import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NextMaterialTable} from '../../../components/material table';
import _ from 'lodash';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import {download, generateCsv, mkConfig} from "export-to-csv";
import {Box} from "@mui/material";

//convert class to function

function ProjectProfitabilityTab({projectProfitabilityData}) {
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [instructionModal, setInstructionModal] = useState(false);

    function instructionToggle() {
        setInstructionModal((instructionToggle_prev) => {
            return !instructionToggle_prev;
        });
    }
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: 'Project_Profitability_Data',
    });

    const handleExportData = () => {
        if (!projectProfitabilityData || projectProfitabilityData.length === 0) {
            console.warn('No data available to export');
            return;
        }
        const formattedData = projectProfitabilityData.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                rowData[col.header] = row[col.accessorKey];
            });
            return rowData;
        });
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);
    };

    const columns = useMemo(() => [{
        header: t('mt.sales.pipeline.tab3.column.project.text'),
        id: 'projectName',
        accessorKey: 'projectName',
        size: 180,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        sortingFn: (rowA, rowB, columnId) => {
            const valueA = rowA.getValue(columnId) || '';
            const valueB = rowB.getValue(columnId) || '';
            return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
        },
        Cell: ({row}) => {
            const rowData = row.original;
            return (<div className="text_ellipsis" title={rowData.projectName}>
                {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
            </div>);
        },
    }, {
        header: t('mt.sales.pipeline.tab3.column.client.name.text'),
        id: 'clientName',
        accessorKey: 'clientName',
        size: 180,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        sortingFn: (rowA, rowB, columnId) => {
            const valueA = rowA.getValue(columnId) || '';
            const valueB = rowB.getValue(columnId) || '';
            return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
        },
        Cell: ({row}) => {
            const rowData = row.original;
            return (<div className="text_ellipsis" title={rowData.clientName}>
                {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
            </div>);
        },
    },

        {
            header: t('mt.sales.pipeline.tab3.column.sales.member.text'),
            id: 'saleMember',
            accessorKey: 'saleMember',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.saleMember}>
                    {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab3.column.project.manager.text'),
            id: 'projectManager',
            accessorKey: 'projectManager',
            size: 180,
            enableResizing: false,
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.projectManager}>
                    {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab3.column.industry.text'),
            id: 'industryName',
            accessorKey: 'industryName',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.industryName}>
                    {!_.isEmpty(rowData.industryName) ? rowData.industryName : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab3.column.solution.text'),
            id: 'solutionName',
            accessorKey: 'solutionName',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.solutionName}>
                    {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab3.column.est.gross.margin.text'),
            id: 'estimatedGrossMargin',
            accessorKey: 'estimatedGrossMargin',
            accessorFn: (row) => row.estimatedGrossMargin,
            size: 180,
            dataType: 'number',
            enableResizing: false,
            Cell: ({cell}) => <div>{cell.getValue()}</div>,

        }, {
            header: t('mt.sales.pipeline.tab3.column.actual.gross.margin.text'),
            id: 'actualGrossMargin',
            accessorKey: 'actualGrossMargin',
            accessorFn: (row) => row.actualGrossMargin,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.project.value.text'),
            id: 'projectValue',
            accessorKey: 'projectValue',
            accessorFn: (row) => row.projectValue,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.total.est.revenue.text'),
            id: 'totalEstimatedRevenue',
            accessorKey: 'totalEstimatedRevenue',
            accessorFn: (row) => row.totalEstimatedRevenue,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.total.est.cost.text'),
            id: 'totalEstimatedCost',
            accessorKey: 'totalEstimatedCost',
            accessorFn: (row) => row.totalEstimatedCost,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.total.invoiced.amt.text'),
            id: 'totalInvoicedAmount',
            accessorKey: 'totalInvoicedAmount',
            accessorFn: (row) => row.totalInvoicedAmount,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.amt.paid.by.client.text'),
            id: 'totalInvoicedAmountPaidByClient',
            accessorKey: 'totalInvoicedAmountPaidByClient',
            accessorFn: (row) => row.totalInvoicedAmountPaidByClient,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab3.column.partner.invoiced.amt.text'),
            id: 'partnerInvoicedAmount',
            accessorKey: 'partnerInvoicedAmount',
            accessorFn: (row) => row.partnerInvoicedAmount,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },

    ]);

    useEffect(() => {
        async function fetchData() {

            set_isLoaded(true);
        }

        fetchData();
    }, []);


    if (!isLoaded) {


        return (<Card className="text-center">
            <CardBody>
                <LoaderSpinner/>
            </CardBody>
        </Card>);
    } else {

        return (<>
            <div className="ms-2" style={{fontSize: '13px'}}>
          <span style={{color: '#333'}}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
              <br/>
              {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
              <a href="#" onClick={instructionToggle} style={{textDecoration: 'underline', color: '#007bff'}}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
            </div>
            <div className="table_hover">
                <NextMaterialTable
                    columns={columns}
                    data={projectProfitabilityData}
                    options={{
                        enableColumnPinning: true,
                        enableColumnResizing: true, initialState: {
                            columnOrder: ['projectName', 'clientName', 'saleMember', 'projectManager', 'industryName', 'solutionName', 'estimatedGrossMargin', 'actualGrossMargin', 'projectValue', 'totalEstimatedRevenue', 'totalEstimatedCost', 'totalInvoicedAmount', 'totalInvoicedAmountPaidByClient', 'partnerInvoicedAmount', 'partnerInvoicedAmount'],
                            columnVisibility: {
                                industryName: false,
                                solutionName: false,
                                saleMember: false,
                                totalEstimatedCost: false,
                                totalEstimatedRevenue: false,
                            },
                        }, // enableRowSelection: true,
                        enableHiding: true,
                        state: {
                            showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
                        },
                        renderTopToolbarCustomActions: () => (
                            <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap'}}>
                                <Button className="next_btn" onClick={handleExportData}>
                                    Export All Data
                                </Button>
                            </Box>),
                    }}
                />
            </div>
            {/*==========================================Instruction Modal=================================================*/}

            <Modal
                isOpen={instructionModal}
                toggle={instructionToggle}
                className="modal-lg"
                backdrop={'static'}
            >
                <ModalHeader toggle={instructionToggle}>{t(`integr8.project.profitability.modal.heading`)}</ModalHeader>
                <ModalBody>
                    <div>
                        <div className={'instruction-body'}>
                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title1`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content1`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title2`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content2`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title3`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content3`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title4`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content4`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title5`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content5`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title6`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content6`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.project.profitability.modal.instruction.title7`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content7`)}</span><br/>

                                <div className={'mt-3 mb-3'} style={{color: '#555', fontFamily: 'Cambria Math'}}>
                <span>Estimated Gross Margin (%) =
                  <div style={{display: 'inline-block'}}><math display={'block'}>
                  <mfrac>
                    <mrow>
                      <mi>(Total Estimated Revenue</mi>
                      <mo>-</mo>
                      <mi>Total Estimated Cost)</mi>
                    </mrow>
                    <mrow>
                      <mi>(Total
                Estimated Revenue)</mi>
                    </mrow>
                  </mfrac>
                </math></div>
                × 100%
                </span>
                                </div>
                            </section>

                            <section className={'instruction-section'}>
                                <h6
                                    className={'instruction-title'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.title8`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.project.profitability.modal.instruction.content8`)}</span><br/>

                                <div className={' mt-3 mb-3'} style={{color: '#555', fontFamily: 'Cambria Math'}}>
                <span>Actual Gross Margin (%) =
                  <div style={{display: 'inline-block'}}><math display={'block'}>
                  <mfrac>
                    <mrow>
                      <mi>(Total Invoiced Amount</mi>
                      <mo>-</mo>
                      <mi>Partner Invoiced Amount)</mi>
                    </mrow>
                    <mrow>
                      <mi>Total Invoiced Amount</mi>
                    </mrow>
                  </mfrac>
                </math></div>
                × 100%
                </span>
                                </div>

                            </section>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button className="integr8_cancel_btn"
                            onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

                </ModalFooter>

            </Modal>

        </>);

    }
}

export default ProjectProfitabilityTab;

import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NextMaterialTable} from '../../../components/material table';
import _ from 'lodash';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import {Box} from "@mui/material";
import {download, generateCsv, mkConfig} from "export-to-csv";

//convert class to function

function PartnerBillingTab({partnerBillingData}) {
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [instructionModal, setInstructionModal] = useState(false);

    function instructionToggle() {
        setInstructionModal((instructionToggle_prev) => {
            return !instructionToggle_prev;
        });
    }

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: 'Partner_Billing_Data',
    });

    const handleExportData = () => {
        if (!partnerBillingData || partnerBillingData.length === 0) {
            console.warn('No data available to export');
            return;
        }
        const formattedData = partnerBillingData.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                rowData[col.header] = row[col.accessorKey];
            });
            return rowData;
        });
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);
    };

    const columns = useMemo(() => [{
        header: t('mt.sales.pipeline.tab4.column.partner.name.text'),
        id: 'partnerName',
        accessorKey: 'partnerName',
        size: 200,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        sortingFn: (rowA, rowB, columnId) => {
            const valueA = rowA.getValue(columnId) || '';
            const valueB = rowB.getValue(columnId) || '';
            return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
        },
        Cell: ({row}) => {
            const rowData = row.original;
            return (<div className="text_ellipsis" title={rowData.partnerName}>
                {!_.isEmpty(rowData.partnerName) ? rowData.partnerName : '-'}
            </div>);
        },
    },
        {
            header: t('mt.sales.pipeline.tab4.column.total.amt.invoiced.fy.text'),
            id: 'totalAmountInvoicedCurrentFY', accessorKey: 'totalAmountInvoicedCurrentFY',
            accessorFn: (row) => !_.isNull(row.totalAmountInvoicedCurrentFY) ? row.totalAmountInvoicedCurrentFY.toLocaleString('en-IN') : 0,
            dataType: 'number',
            size: 200,
            enableResizing: false,
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },
        {
            header: t('mt.sales.pipeline.tab4.column.total.amt.paid.fy.text'),
            id: 'totalAmountPaidCurrentFY',
            accessorKey: 'totalAmountPaidCurrentFY',
            accessorFn: (row) => !_.isNull(row.totalAmountPaidCurrentFY) ? row.totalAmountPaidCurrentFY.toLocaleString('en-IN') : 0,
            size: 200,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab4.column.unpaid.invoiced.amt.fy.text'),
            id: 'unpaidInvoiceAmountCurrentFY',
            accessorKey: 'unpaidInvoiceAmountCurrentFY',
            accessorFn: (row) => !_.isNull(row.unpaidInvoiceAmountCurrentFY) ? row.unpaidInvoiceAmountCurrentFY.toLocaleString('en-IN') : 0,
            size: 190,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab4.column.payment.done.more.than.75.days.text'),
            id: 'numberOfPaymentsOver75Days',
            accessorKey: 'numberOfPaymentsOver75Days',
            accessorFn: (row) => !_.isNull(row.numberOfPaymentsOver75Days) ? row.numberOfPaymentsOver75Days.toLocaleString('en-IN') : 0,
            size: 190,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab4.column.payment.done.65.to.75.days.text'),
            id: 'numberOfPayments65To75Days',
            accessorKey: 'numberOfPayments65To75Days',
            accessorFn: (row) => !_.isNull(row.numberOfPayments65To75Days) ? row.numberOfPayments65To75Days.toLocaleString('en-IN') : 0,
            size: 190,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab4.column.payment.done.45.to.65.days.text'),
            id: 'numberOfPayments45To65Days',
            accessorKey: 'numberOfPayments45To65Days',
            accessorFn: (row) => !_.isNull(row.numberOfPayments45To65Days) ? row.numberOfPayments45To65Days.toLocaleString('en-IN') : 0,
            size: 190,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab4.column.payment.done.less.than.45.days.text'),
            id: 'numberOfPaymentsLessThan45Days',
            accessorKey: 'numberOfPaymentsLessThan45Days',
            accessorFn: (row) => !_.isNull(row.numberOfPaymentsLessThan45Days) ? row.numberOfPaymentsLessThan45Days.toLocaleString('en-IN') : 0,
            size: 180,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }]);


    useEffect(() => {
        async function fetchData() {

            set_isLoaded(true);
        }

        fetchData();
    }, []);

    if (!isLoaded) {


        return (<Card className="text-center">
            <CardBody>
                <LoaderSpinner/>
                {/*<h2 className="mb-3">{t('admin.loading.wait')}</h2>*/}
            </CardBody>
        </Card>);
    } else {

        return (<>
            <div className="ms-2" style={{fontSize: '13px'}}>
          <span style={{color: '#333'}}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
              <br/>
              {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
              <a href="#" onClick={instructionToggle} style={{textDecoration: 'underline', color: '#007bff'}}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
            </div>
            <div className="">
                <div className="table_hover">
                    <NextMaterialTable
                        columns={columns}
                        data={partnerBillingData}
                        options={{
                            enableColumnPinning: true,
                            enableColumnResizing: true,
                            initialState: {
                                columnOrder: ['partnerName', 'totalAmountInvoicedCurrentFY', 'totalAmountPaidCurrentFY', 'unpaidInvoiceAmountCurrentFY', 'numberOfPaymentsOver75Days', 'numberOfPayments65To75Days', 'numberOfPayments45To65Days', 'numberOfPaymentsLessThan45Days'],
                                pagination: {pageSize: 50, pageIndex: 0},
                            },
                            state: {
                                showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
                            },
                            muiPaginationProps: {
                                rowsPerPageOptions: [50, 100, 200, 300, 400],
                                showRowsPerPage: true,
                            },
                            renderTopToolbarCustomActions: () => (
                                <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap'}}>
                                    <Button className="next_btn" onClick={handleExportData}>
                                        Export All Data
                                    </Button>
                                </Box>),
                        }}
                    />
                </div>


                {/*==========================================Instruction Modal=================================================*/}

                <Modal
                    isOpen={instructionModal}
                    toggle={instructionToggle}
                    className="modal-lg"
                    backdrop={'static'}
                >
                    <ModalHeader toggle={instructionToggle}>{t(`integr8.partner.billing.modal.heading`)}</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className={'instruction-body'}>
                                <section className={'instruction-section'}>
                                    <h6 className={'instruction-title'}>{t(`integr8.partner.billing.modal.total.amount.invoiced`)}</h6>
                                    <span
                                        className={'instruction-content'}>&nbsp;{t(`integr8.partner.billing.modal.sum.of.the`)}</span>
                                </section>

                                <section className={'instruction-section'}>
                                    <h6 className={'instruction-title'}>{t(`integr8.partner.billing.modal.total.amount.paid`)}</h6>
                                    <span
                                        className={'instruction-content'}>&nbsp;{t(`integr8.partner.billing.modal.sum.of.the.amounts.invoice`)}</span>
                                </section>

                                <section className={'instruction-section'}>
                                    <h6 className={'instruction-title'}>{t(`integr8.partner.billing.modal.unpaid.invoices`)}</h6>
                                    <span
                                        className={'instruction-content'}>&nbsp;{t(`integr8.partner.billing.modal.difference.between`)} </span>
                                </section>

                                <div className={'instruction-section'}>
                                    <h6 className={'instruction-title'}>{t(`integr8.partner.billing.modal.days.outstanding.amounts`)}</h6>
                                    <br/>
                                    <div className={'mt-2 mb-2'}><span
                                        className={'instruction-content'}>&nbsp;{t(`integr8.partner.billing.modal.any.invoices.approved`)}</span>
                                    </div>
                                    <ul>
                                        <li style={{color: '#555', lineHeight: '25px'}}><span
                                            style={{fontWeight: '600'}}>{t(`integr8.partner.billing.modal.75.days.out`)}</span>&nbsp;{t(`integr8.partner.billing.modal.sum.of.unpaid`)}
                                        </li>
                                        <li style={{color: '#555', lineHeight: '25px'}}><span
                                            style={{fontWeight: '600'}}>{t(`integr8.partner.billing.modal.45.to.75`)}	</span>&nbsp;{t(`integr8.partner.billing.modal.sum.of.45.to.75`)}
                                        </li>
                                        <li style={{color: '#555', lineHeight: '25px'}}><span
                                            style={{fontWeight: '600'}}>{t(`integr8.partner.billing.modal.45.days`)}</span>&nbsp;{t(`integr8.partner.billing.modal.sum.paid.45.days`)}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button className="integr8_cancel_btn"
                                onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

                    </ModalFooter>;

                </Modal>

            </div>
        </>);

    }
}

export default PartnerBillingTab;

import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';


const CustomerProjects = Loadable(lazy(() => import('../pages/customer/customer-project')));
const CustomerProjectInvoices = Loadable(lazy(() => import('../pages/customer/customer-project-invoices')));
const CustomerIndividualProject = Loadable(lazy(() => import('../pages/customer/customer-individual-project')));
const CustomerMyProfile = Loadable(lazy(() => import('../pages/customer/customer-my-profile')));
const CustomerDashboard = Loadable(lazy(() => import('../pages/customer/customer-dashboard')));
const CustomerAccounts = Loadable(lazy(() => import('../pages/customer/customer-account')));
const CustomerUpdates = Loadable(lazy(() => import('../pages/customer/customer-updates')));
const IndividualUpdates = Loadable(lazy(() => import('../pages/common-components/individual-updates')));
const GoToOldIntegr8 = Loadable(lazy(() => import('../pages/program-manager/go-to-old-integr8')));
const BackToClient = Loadable(lazy(() => import('../pages/customer/back-to-client')));
const route_path = conf_prop.get('route_path');

const CustomerRoutes = [


    {
        path: `${route_path}/customer/projects`,
        name: 'Projects',
        exact: true,
        element: <CustomerProjects />,
    },

    {
        path: `${route_path}/customer/individual-project/:projectId_ENCRYPTED`,
        name: 'individual-project',
        exact: true,
        element: <CustomerIndividualProject />,
    },
    {
        path: `${route_path}/customer/my-profile`,
        name: 'My Profile',
        exact: true,
        element: <CustomerMyProfile />,
    },
    {
        path: `${route_path}/customer/updates`,
        name: 'Updates',
        exact: true,
        element: <CustomerUpdates/>,
    },
    {
        path: `${route_path}/customer/project-invoices`,
        name: 'Project Invoices',
        exact: true,
        element: <CustomerProjectInvoices/>,
    },

    {
        path: `${route_path}/customer/dashboard`,
        name: 'Dashboard',
        exact: true,
        element: <CustomerDashboard />,
    },

    {
        path: `${route_path}/customer/account`,
        name: 'Account',
        exact: true,
        element: <CustomerAccounts/>,
    },
    {
        path: `${route_path}/customer/back-to-client`,
        name: 'Back To Client',
        exact: true,
        element: <BackToClient/>,

    },

    {
        path: `${route_path}/customer/individual-updates/:mesId_ENCRYPTED`,
        name: 'IndividualUpdates',
        exact: true,
        element: <IndividualUpdates/>,
    },

    {
        path: `${route_path}/customer/nextv3`,
        name: 'Go to nextV3',
        exact: true,
        element: <GoToOldIntegr8/>,
    },

    {
        path: '*', element: <Navigate to="/auth/404"/>
    },

];
export default CustomerRoutes;

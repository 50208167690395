import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Input, Row} from 'reactstrap';
// import img2 from '../../assets/images/users/4.jpg';
import ApiUtils from '../../../api/ApiUtils';
import ClientController from '../../../api/controller/client-controller';
import {useTranslation} from 'react-i18next';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import Select from 'react-select';
import update from 'immutability-helper';
import ContactController from '../../../api/controller/contact-controller';
import {clientContactInsertSchema} from '../../../validation/client-contact-validation';
import {Box, Drawer} from '@mui/material';
import {NextMaterialTable} from '../../../components/material table';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';


function PmMtSalesManageContacts(props) {

  const { t } = useTranslation();
  const userInfo = ApiUtils.getLocalStorage('userInfo');
  const [clients, set_clients] = useState([]);
  const [clientContactMappers, set_clientContactMappers] = useState([]);
  const [isLoaded, set_isLoaded] = useState(false);
  const [isUpLoaded, set_isUpLoaded] = useState(false);
  const [addManageContactModal, set_addManageContactModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const schema = useMemo(() => clientContactInsertSchema, []);
  const [emails, set_emails] = useState([]);
  const [isLinkVisible, setIsLinkVisible] = useState(null);
  const [state1, setState1] = useState({ right: false });
  const [state2, setState2] = useState({ right: false });
  const [state3, setState3] = useState({ right: false });
  const [clientContacts, set_clientContacts] = useState([]);
  const [newContact, set_newContact] = useState({
    name: '',
    designation: '',
    email: '',
    contactNumber: '',
    homeAddress: null,
    officeAddress: null,
    clientContactMappers: [{
      client: {},
    }], userInfo: { userId: userInfo.userId },
  });

  const [updateContact, set_updateContact] = useState({
    name: null,
    designation: null,
    email: null,
    contactNumber: null,
    homeAddress: null,
    officeAddress: null,
    userInfo: { userId: userInfo.userId },
  });

  function validateDisable() {
    schema.validate(newContact).catch(err => console.warn(err));
    return !schema.isValidSync(newContact);
  }


  function findAllClient() {
    ClientController.findAllClientAndContact().then(results => {
      const clients = results.map(client => {
        return {
          ...client,
          label: client.name,
          value: client.clientId,
          clientContactMappers: client.clientContactMappers.map(clientContactMapper => {
            return {
              ...clientContactMapper, client: { name: client.name, status: client.status },
            };
          }),
        };

      });
      set_clients(clients);
      set_clientContactMappers(clients.flatMap(client => client.clientContactMappers));
      // set_clientContacts(results);
    }).catch(error => console.error(error));
  }

  function findAllContact() {
    ContactController.findAll().then(results => {
      const contacts = results.map(contact => {
        return {
          ...contact,
          label: contact.name,
          value: contact.clientId,

        };

      });
      set_clientContacts(contacts);
      const emails = contacts.map((clientContact) => clientContact.email);
      set_emails(emails);
    }).catch(error => console.error(error));
  }

  function insertContact() {
    const matchEmail = !_.isEmpty(newContact.email) && emails.includes(newContact.email);

    if (matchEmail) {
      toastify('error', t('pm.mt.sales.add.contacts.email.already.exists'), t('pm.mt.sales.add.contacts.email.already.exists'));
    } else {
      set_isUpLoaded(true);
      ContactController.insertClientContact(newContact).then(result => {
        toastify('success', t('pm.mt.sales.add.contacts.inserted'), t('pm.mt.sales.add.contacts.inserted'));
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        set_isUpLoaded(false);
        setState1(!open);
        findAllClient();
      });
    }
  }

  function updateContactDetails() {


    if (!_.isEmpty(updateContact.name) && !_.isEmpty(updateContact.email) && !_.isEmpty(updateContact.designation)) {
      set_isUpLoaded(true);
      ContactController.updateContact(updateContact).then(result => {
      toastify('success', t('pm.mt.sales.add.contacts.updated'), t('pm.mt.sales.add.contacts.updated'));
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      set_isUpLoaded(false);
      setState2(!open);
      setState3(!open);
      findAllClient();
    });
    } else {
      toastify('error', t('common.toastify.message.some.fields.are.blank'), t('common.toastify.message.some.fields.are.blank'));
    }
  }


  function onClientDetailsChange(e) {
    set_newContact(update(newContact, { [e.target.name]: { $set: e.target.value } }));
  }

  function onEmailDetailsChange(e) {
    set_newContact(update(newContact, { [e.target.name]: { $set: e.target.value } }));
    if (emails.map(email => email.toLowerCase().toUpperCase()).includes(e.target.value.toLowerCase().toUpperCase())) {
      setIsLinkVisible(true);
    } else {
      setIsLinkVisible(false);
    }
  }


  function onClientDetailsUpdate(e) {
    set_updateContact(update(updateContact, { [e.target.name]: { $set: e.target.value } }));

  }

  function onClientChange(event) {
    set_newContact(update(newContact, { clientContactMappers: { 0: { client: { 'clientId': { $set: event.clientId } } } } }));
  }

  const toggleDrawerAdd = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState1({ ...state1, [anchor]: open });
  };

  const toggleDrawerSee = (rowData, anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setCurrentRow(rowData);
    setState2({ ...state2, [anchor]: open });
  };

  const toggleDrawerEdit = (row, anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState3({ ...state3, [anchor]: open });
    set_updateContact({
      name: currentRow.contact.name,
      designation: currentRow.contact.designation,
      email: currentRow.contact.email,
      contactNumber: currentRow.contact.contactNumber,
      homeAddress: currentRow.contact.homeAddress,
      officeAddress: currentRow.contact.officeAddress,
      userInfo: { userId: userInfo.userId },
      contactId: currentRow.contact.contactId,
    });
    toggleDrawerSee(row, anchor, false);
  };

  const handleCancelToggle = (row, anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setCurrentRow(row);
    setState2({ ...state2, [anchor]: !open });
    setState3({ ...state3, [anchor]: !open });
  };


  /*--------------------------------------------Add Contact Drawer---------------------------------------------------*/

  const addList = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins' }}
      role="presentation"
    >
      <Row>
        <Col md={6}>
          <div className={'d-flex ms-4 mt-4'}>
            <i className="fa-solid fa-xmark mt-1" style={{ color: 'gray', cursor: 'pointer' }}
               onClick={toggleDrawerAdd(anchor, false)}></i>
            <div className={'ms-3'} style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#646464',
            }}>{t('manage.contact.pm.add.contact.header.text')}
            </div>
          </div>

        </Col>
        <Col md={6}>
          <div className={'text-end mt-3 me-3'}>
            <Button className="next_cancel_btn "
                    onClick={toggleDrawerAdd(anchor, false)}
            >
              {t('pm.project.cancel.btn')}
            </Button>
            <ButtonWithLoader
              className=" next_btn ms-2"
              disabled={(validateDisable() || isLinkVisible === true)}
              loading={isUpLoaded}
              onClick={insertContact}
              text={t('pm.project.add.btn')}
            >
            </ButtonWithLoader>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="container font-13">
        <div className="mt-2">
          <Row>
            <Col md={3} className={'mt-1'}><label
              className="mt-1">{t('manage.contact.pm.contact.name.text')}</label></Col>
            <Col md={9}>
              <Input type="text" name="name"
                     onChange={onClientDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.email.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="email"
                     onChange={onEmailDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
              {_.isNull(isLinkVisible) ? (<span></span>) : (
                <div>
                  {isLinkVisible ? (
                    <span className="text-danger"
                          style={{ fontSize: '12px' }}>This Email Id Already Exists!!!Try Another<br /></span>
                  ) : ('')}
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2">{t('manage.contact.pm.contact.designation.text')}</label></Col>
            <Col md={9}>
              <Input type="text" name="designation"
                     onChange={onClientDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>

          </Row>

        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.company.text')}</label></label></Col>
            <Col md={9} className={'mb-2'}>
              <Select
                options={clients}
                onChange={(event) => onClientChange(event)}
                classNamePrefix="next_select"
                placeholder={t('pm.map.client.placeholder.select')} />
            </Col>
          </Row>
        </div>


        <div className="mt-2">
          <Row>
            <Col md={3}><label
            ><label>{t('manage.contact.pm.contact.contactnumber.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="contactNumber" maxLength="10"
                     onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                     onChange={onClientDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.officeaddress.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="officeAddress"
                     onChange={onClientDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.homeaddress.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="homeAddress"
                     onChange={onClientDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>

      </div>
    </Box>);

  /*-------------------------- see icon Drawer item-----------------*/
  const list = (anchor, row) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
      role="presentation"
    >
      <div>
        <Row>
          <Col md={6}>
            <div className={'d-flex ms-4 mt-4'}>
              <i className="fa-solid fa-xmark mt-1" style={{ color: 'gray', cursor: 'pointer' }}
                 onClick={toggleDrawerSee(row, anchor, false)}>
              </i>
              <div className={'ms-3'}
                   style={{
                     fontSize: '14px',
                     fontWeight: 600,
                     color: '#646464',
                   }}>{t('manage.contact.pm.contact.header.text')}
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className={'d-flex text-end mt-3 ms-5'}>
              <Button className="next_cancel_btn" onClick={toggleDrawerSee(row, anchor, false)}>
                {t('pm.project.cancel.btn')}
              </Button>
              <div className={'ms-2'}>
                {['right'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button className="next_btn"
                            onClick={toggleDrawerEdit(row, anchor, true)}
                    >{t('pm.project.manage.contact.drawer.edit.btn')}</Button>
                    <Drawer
                      anchor={anchor}
                      open={state3[anchor]}
                      onClose={toggleDrawerEdit(row, anchor, false)}
                      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                      PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.1)', overflow: 'hidden'}}}
                    >
                      {editList(anchor)}
                    </Drawer>
                  </React.Fragment>))}
              </div>

            </div>
          </Col>
        </Row>
        <hr />
        <div className="container m-4 font-13 ">
          <div className="mt-4">
            <Row>
              <Col md={4}><label className="mt-1">{t('manage.contact.pm.contact.name.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.name : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.email.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.email : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.designation.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.designation : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.company.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.client ? row.client.name : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.contactnumber.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.contactNumber : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.officeaddress.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.officeAddress : ''}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}><label
                className="mt-1">{t('manage.contact.pm.contact.homeaddress.text')}</label></Col>
              <Col md={8}>
                <div className="mt-1 mb-1">{row && row.contact ? row.contact.homeAddress : ''}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Box>
  );

  /*-----------------------------------------------Edit Drawer item----------------------------------------------*/

  const editList = (anchor, row) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins' }}
      role="presentation"

    >
      <Row>
        <Col md={6}>
          <div className={'d-flex ms-4 mt-4'}>
            <i className="fa-solid fa-xmark mt-1" style={{ color: 'gray', cursor: 'pointer' }}
               onClick={handleCancelToggle(row, anchor, true)}></i>
            <div className={'ms-3'} style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#646464',
            }}>{t('manage.contact.pm.edit.contact.header.text')}
            </div>

          </div>
        </Col>
        <Col md={6}>
          <div className={'text-end mt-3 me-3'}>
            <Button className="next_cancel_btn "
                    onClick={handleCancelToggle(row, anchor, true)}
            >
              {t('pm.project.cancel.btn')}
            </Button>
            <ButtonWithLoader
              className=" next_btn ms-2"
              // disabled={validateDisable()}
              loading={isUpLoaded}
              onClick={updateContactDetails}
              text={t('pm.project.manage.contact.drawer.update.btn')}
            >
            </ButtonWithLoader>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="container font-13">
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2">{t('manage.contact.pm.contact.name.text')}</label></Col>
            <Col md={9}>
              <Input type="text" name="name"
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.name : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>

          </Row>

        </div>

        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.email.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="email"
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.email : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>

        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2">{t('manage.contact.pm.contact.designation.text')}</label></Col>
            <Col md={9}>
              <Input type="text" name="designation"
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.designation : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>

          </Row>

        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.company.text')}</label></label></Col>
            <Col md={9} className="mb-2">
              <Select
                options={clients}
                isDisabled={true}
                // onChange={onClientDetailsUpdate}
                defaultValue={{ label: currentRow.client.name, value: currentRow.clientId }}

                classNamePrefix="next_select"
                placeholder={t('pm.map.client.placeholder.select')} />
            </Col>
          </Row>
        </div>


        <div className="mt-2">
          <Row>
            <Col md={3}><label
            ><label>{t('manage.contact.pm.contact.contactnumber.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="contactNumber" maxLength="10"
                     onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.contactNumber : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.officeaddress.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="officeAddress"
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.officeAddress : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={3}><label
              className="mt-2"><label>{t('manage.contact.pm.contact.homeaddress.text')}</label></label></Col>
            <Col md={9}>
              <Input type="text" name="homeAddress"
                     onChange={onClientDetailsUpdate}
                     defaultValue={currentRow && currentRow.contact ? currentRow.contact.homeAddress : ''}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>

      </div>
    </Box>);
  /*------------------------------------------------------------------------------------------------------------*/

  const columns = useMemo(() => [
    {
      header: t('partner.project.column.client.text'),
      accessorFn: (row) => _.isEmpty(row.client.name) ? '-' : row.client.name,
      // size: 100,
    },
    {
      header: t('integr8.all.manage.contact.col2.text'),
      accessorFn: (row) => _.isEmpty(row.contact.name) ? '-' : row.contact.name,
      // size: 150,
    },
    {
      header: t('integr8.all.manage.contact.col3.text'),
      accessorFn: (row) => _.isEmpty(row.contact.designation) ? '-' : row.contact.designation,
      // size: 120,

    },
    {
      header: t('integr8.all.manage.contact.col4.text'),
      accessorFn: (row) => _.isEmpty(row.contact.email) ? '-' : row.contact.email,
      // size: 100,

    },

    {
      header: t('integr8.all.manage.contact.col5.text'),
      accessorFn: (row) => _.isEmpty(row.contact.contactNumber) ? '-' : row.contact.contactNumber,
      // size: 180,
    },
    {
      header: '',
      size: 50,
      id: 'edit',
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <div>
            <i className="fas fa-eye" style={{ color: 'gray', cursor: 'pointer' }}
               onClick={toggleDrawerSee(rowData, 'right', true)}></i>
            <Drawer
              anchor="right"
              open={state2.right}

              onClose={toggleDrawerSee(null, 'right', false)}
              BackdropProps={{ style: { backgroundColor: 'transparent' } }}
              PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.1)', overflow: 'hidden'}}}

            >
              {currentRow && list('right', currentRow)}
            </Drawer>
          </div>
        );
      },
    },
  ]);


  useEffect(() => {
    async function fetchData() {
      await findAllClient();
      await findAllContact();
      set_isLoaded(true);
    }

    fetchData();
  }, []);


  if (!isLoaded) {
    return (<div style={{ marginTop: '15%' }}><LoaderSpinner /></div>);
  } else {
    return (<div>
      <Row>
        <Col md={6} className="ps-0 pe-0">
          <h4><b>{t('pm.manage.client.page.header.text')}</b></h4>
        </Col>
        <Col className="text-end" md={6}>

          <div>
            {['right'].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button className="next_btn"
                        onClick={toggleDrawerAdd(anchor, true)}
                >{t('program.manager.manage.contact.add.contact.button.text')}</Button>
                <Drawer
                  anchor={anchor}
                  open={state1[anchor]}
                  onClose={toggleDrawerAdd(anchor, false)}
                  BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                  PaperProps={{style: {boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)', overflow: 'hidden'}}}
                >
                  {addList(anchor)}
                </Drawer>
              </React.Fragment>))}
          </div>
        </Col>
      </Row>

      <div className="containt_e2e1 table_hover mt-3" style={{ clipPath: 'content-box' }}>
        <NextMaterialTable
          columns={columns}
          data={_.orderBy(clientContactMappers, ['ccmId'], ['desc'])}
          options={{
            state: {
              showLoadingOverlay: !isLoaded,
              showSkeletons: !isLoaded,
            },


          }}
        />
      </div>

    </div>);
  }
}

export default PmMtSalesManageContacts;

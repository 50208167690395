import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {NextMaterialTable} from '../../../components/material table';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import {download, generateCsv, mkConfig} from "export-to-csv";
import {Box} from "@mui/material";


function SalesMtSalesPipelineTab({salesPipeLineData}) {
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [instructionModal, setInstructionModal] = useState(false);

    function instructionToggle() {
        setInstructionModal((instructionToggle_prev) => {
            return !instructionToggle_prev;
        });
    }

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: 'Sales_Pipeline_Data'
    });

    const handleExportData = () => {
        if (!salesPipeLineData || salesPipeLineData.length === 0) {
            console.warn('No data available to export');
            return;
        }
        const formattedData = salesPipeLineData.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                rowData[col.header] = row[col.accessorKey];
            });
            return rowData;
        });
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);
    };

    const columns = useMemo(() => [{
        header: t('mt.sales.pipeline.tab2.column.client.name.text'),
        id: 'clientName',
        accessorKey: 'clientName',
        accessorFn: (row) => !_.isEmpty(row.clientName) ? row.clientName : '-',
        size: 180,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        sortingFn: (rowA, rowB, columnId) => {
            const valueA = rowA.getValue(columnId) || '';
            const valueB = rowB.getValue(columnId) || '';
            return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
        },
        Cell: ({row}) => {
            const rowData = row.original;
            return (<div className="text_ellipsis" title={rowData.clientName}>
                {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
            </div>);
        },
    }, {
        header: t('mt.sales.pipeline.tab2.column.project.text'),
        id: 'projectName',
        accessorKey: 'projectName',
        accessorFn: (row) => row.projectName,
        size: 220,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        sortingFn: (rowA, rowB, columnId) => {
            const valueA = rowA.getValue(columnId) || '';
            const valueB = rowB.getValue(columnId) || '';
            return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
        },
        Cell: ({row}) => {
            const rowData = row.original;
            return (<div className="text_ellipsis" title={rowData.projectName}>
                {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
            </div>);
        },
    },
        {
            header: t('mt.sales.pipeline.tab2.column.status.text'),
            id: 'projectStatus',
            // accessorFn: (row) => !_.isEmpty(row.projectStatus) ? row.projectStatus : '-',
            accessorKey: 'projectStatus',
            size: 130,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.projectStatus}>
                    {!_.isEmpty(rowData.projectStatus) ? rowData.projectStatus : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab2.column.project.value.text'),
            id: 'projectValue',
            // accessorFn: (row) => !_.isNull(row.projectValue) ? row.projectValue : '0',
            accessorKey: 'projectValue',
            size: 140,
            enableResizing: false,
            Cell: ({row}) => {
                const rowData = row.original;
                return (<span>
            {!_.isNull(rowData.projectValue) ? rowData.projectValue.toLocaleString('en-IN') : 0}
          </span>);
            },
        },

        {
            header: t('mt.sales.pipeline.tab2.column.sales.member.text'),
            id: 'saleMember',
            accessorKey: 'saleMember',
            accessorFn: (row) => !_.isEmpty(row.saleMember) ? row.saleMember : '-',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.saleMember}>
                    {!_.isEmpty(rowData.saleMember) ? rowData.saleMember : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab2.column.project.manager.text'),
            id: 'projectManager',
            accessorKey: 'projectManager',
            accessorFn: (row) => !_.isEmpty(row.projectManager) ? row.projectManager : '-',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.projectManager}>
                    {!_.isEmpty(rowData.projectManager) ? rowData.projectManager : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab2.column.industry.text'),
            id: 'industryName',
            accessorKey: 'industryName',
            accessorFn: (row) => !_.isEmpty(row.industryName) ? row.industryName : '-',
            size: 140,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.industryName}>
                    {!_.isEmpty(rowData.industryName) ? rowData.industryName : '-'}
                </div>);
            },
        }, {
            header: t('mt.sales.pipeline.tab2.column.solution.text'),
            id: 'solutionName',
            // accessorFn: (row) => !_.isEmpty(row.solutionName) ? row.solutionName : '-',
            accessorKey: 'solutionName',
            size: 180,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            sortingFn: (rowA, rowB, columnId) => {
                const valueA = rowA.getValue(columnId) || '';
                const valueB = rowB.getValue(columnId) || '';
                return valueA.localeCompare(valueB, undefined, {sensitivity: 'base', numeric: true});
            },
            Cell: ({row}) => {
                const rowData = row.original;
                return (<div className="text_ellipsis" title={rowData.solutionName}>
                    {!_.isEmpty(rowData.solutionName) ? rowData.solutionName : '-'}
                </div>);
            },
        }
    ]);


    useEffect(() => {
        async function fetchData() {

            set_isLoaded(true);
        }

        fetchData();
    }, []);

    if (!isLoaded) {


        return (<Card className="text-center">
            <CardBody>
                <LoaderSpinner/>
            </CardBody>
        </Card>);
    } else {

        return (<>
            <div className="ms-2" style={{fontSize: '13px'}}>
          <span style={{color: '#333'}}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
              <br/>
              {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
              <a href="#" onClick={instructionToggle} style={{textDecoration: 'underline', color: '#007bff'}}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
            </div>
            <div className="table_hover">
                <NextMaterialTable
                    columns={columns}
                    data={_.orderBy(salesPipeLineData, ['genDate'], ['desc'])}
                    options={{
                        enableColumnPinning: true,
                        enableColumnResizing: true, initialState: {
                            columnOrder: ['clientName', 'projectName', 'projectStatus', 'projectValue', 'saleMember', 'projectManager', 'industryName', 'solutionName'],
                            pagination: {pageSize: 100, pageIndex: 0},
                        },
                        state: {
                            showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
                        },
                        muiPaginationProps: {
                            rowsPerPageOptions: [100, 200, 300, 400, 500],
                            showRowsPerPage: true,
                        },
                        renderTopToolbarCustomActions: () => (
                            <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap'}}>
                                <Button className="next_btn" onClick={handleExportData}>
                                    Export All Data
                                </Button>
                            </Box>),
                    }}
                />
            </div>
            {/*==========================================Instruction Modal=================================================*/}

            <Modal
                isOpen={instructionModal}
                toggle={instructionToggle}
                className="modal-lg"
                backdrop="static"
            >
                <ModalHeader toggle={instructionToggle}>{t(`integr8.sales.pipeline.modal.heading`)}</ModalHeader>
                <ModalBody>
                    <div>
                        <div className={'instruction-body'}>
                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.sales.pipeline.modal.instruction.title1`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.sales.pipeline.modal.instruction.content1`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.sales.pipeline.modal.instruction.title2`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.sales.pipeline.modal.instruction.content2`)}</span>
                            </section>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button className="integr8_cancel_btn"
                            onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

                </ModalFooter>

            </Modal>

        </>);

    }
}

export default SalesMtSalesPipelineTab;

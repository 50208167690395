import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Input, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import ApiUtils from '../../../api/ApiUtils';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Box, Drawer} from '@mui/material';
import {useParams} from 'react-router-dom';
import ClientBillingController from '../../../api/controller/clientbilling-controller';
import update from 'immutability-helper';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';
import {billingValidationSchema} from '../../../validation/billing-validation';


function BillingEntityView({ project, findProjectById, billingEntityDetails, findClientBillingListByProject }) {

  let userInfo = ApiUtils.getLocalStorage('userInfo');
  const { clientId_ENCRYPTED } = useParams();
  const { t } = useTranslation();
  const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
  const [isUpdateBtnLoaded, set_isUpdateBtnLoaded] = useState(false);
  const [state1, setState1] = useState({ right: false });
  const [state2, setState2] = useState({ right: false });
  const [newClientBilling, set_newClientBilling] = useState({
    billingName: '',
    billingAddress1: '',
    billingAddress2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    gstNo: '',
    panNo: '',
    tinNo: '',
    pocName: '',
    pocPhone: '',
    pocEmail: ''
  });

  const [updateClientBilling, set_updateClientBilling] = useState({
    billingName: '',
    billingAddress1: '',
    billingAddress2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    gstNo: '',
    panNo: '',
    tinNo: '',
    pocName: '',
    pocPhone: '',
    pocEmail: ''
  });

  const schema = useMemo(() => billingValidationSchema, []);

  function disableBillingBtn() {
    return !schema.isValidSync(newClientBilling);
  }

  const toggleDrawerAdd = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState1({ ...state1, [anchor]: open });
  };

  const toggleDrawerEdit = (billingEntityDetail, anchor, open) => (event) => {

    set_updateClientBilling(billingEntityDetail);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState2({ ...state2, [anchor]: open });
  };

  const handleCancletoggle = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState2(!open);
  };

  function onBillingDetailsChange(e) {
    set_newClientBilling(update(newClientBilling, { [e.target.name]: { $set: e.target.value } }));
  }

  function updateBillingdetails(e) {
    set_updateClientBilling(update(updateClientBilling, { [e.target.name]: { $set: e.target.value } }));
  }

  async function insertClientBillingDetails() {

    set_isAddBtnLoading(true);
    newClientBilling.client = project.projectClientMappers[0].client;


    await ClientBillingController.insertClientBilling(newClientBilling).then(result => {
      set_newClientBilling({});
      set_isAddBtnLoading(false);
      toastify('success', t('admin.blng.dtls.details.added'), t('admin.blng.dtls.details.added'));

    }).catch((error) => {
      console.error(error);

    }).finally(() => {

      set_isAddBtnLoading(false);
      setState1({ right: false });
      findClientBillingListByProject(project.projectId);
    });

  }

  async function updateClientBillingDetails() {

    if (!_.isEmpty(updateClientBilling.billingName) && !_.isEmpty(updateClientBilling.billingAddress1)
        && !_.isEmpty(updateClientBilling.city) && !_.isEmpty(updateClientBilling.country)
        && !_.isEmpty(updateClientBilling.gstNo) && !_.isEmpty(updateClientBilling.state)
        && !_.isEmpty(updateClientBilling.zipCode) && !_.isEmpty(updateClientBilling.panNo)) {
    set_isUpdateBtnLoaded(true);
    await ClientBillingController.updateClientBilling(updateClientBilling).then(result => {
      set_isUpdateBtnLoaded(false);
      toastify('success', t('admin.blng.dtls.details.updated'), t('admin.blng.dtls.details.updated'));

    }).catch((error) => {
      console.error(error);

    }).finally(() => {

      set_isUpdateBtnLoaded(false);
      setState2({ right: false });
      findClientBillingListByProject(project.projectId);
    });

    } else {
      toastify('error', t('common.toastify.message.some.fields.are.blank'), t('common.toastify.message.some.fields.are.blank'));
    }

  }

  /*==========================================Add Billing Entity===============================================*/

  const addList = (anchor, row) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins' }}
      role="presentation"

    >
      <Row>
        <Col md={6}>
          <div className={'d-flex ms-4 mt-4'}>
            <i className="fa-solid fa-xmark mt-1" style={{ color: 'gray', cursor: 'pointer' }}
               onClick={toggleDrawerAdd(anchor, false)}></i>
            <div className={'ms-3'} style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#646464',
            }}>{t('admin.blng.dtls.header.add.blng.details')}
            </div>
          </div>

        </Col>
        <Col md={6}>
          <div className={'text-end mt-3 me-3'}>
            <Button className="next_cancel_btn "
                    onClick={toggleDrawerAdd(anchor, false)}
            >
              {t('pm.project.cancel.btn')}
            </Button>
            <ButtonWithLoader
              className=" next_btn ms-2"
              disabled={disableBillingBtn()}
              loading={isAddBtnLoading}
              onClick={insertClientBillingDetails}
              text={t('pm.project.add.btn')}
            >
            </ButtonWithLoader>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="container font-13">
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1">{t('admin.blng.dtls.billing.name')}*</label></Col>
            <Col md={8}>
              <Input type="text" name="billingName"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>


          </Row>

        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.address.line1')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="billingAddress1"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.address.line2')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="billingAddress2"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.city')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="city"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.country')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="country"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.state')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="state"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.zipcode')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="zipCode"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1">{t('admin.blng.dtls.gst.no')}*</label></Col>
            <Col md={8}>
              <Input type="text" name="gstNo"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>

          </Row>

        </div>

        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.pan.no')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="panNo"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('admin.blng.dtls.tin.no')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="tinNo"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.name')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocName"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.contact.number')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocPhone" maxLength="10"
                     onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.email.id')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocEmail"
                     onChange={onBillingDetailsChange}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>

      </div>

    </Box>);

  /*==========================================Update Billing Details===============================================*/

  const updateList = (anchor, row) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500, fontFamily: 'Poppins' }}
      role="presentation"

    >
      <Row>
        <Col md={6}>
          <div className={'d-flex ms-4 mt-4'}>
            <i className="fa-solid fa-xmark mt-1" style={{ color: 'gray', cursor: 'pointer' }}
               onClick={handleCancletoggle(anchor, true)}></i>
            <div className={'ms-3'} style={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#646464',
            }}>{t('admin.blng.dtls.header.update.blng.details')}
            </div>
          </div>

        </Col>
        <Col md={6}>
          <div className={'text-end mt-3 me-3'}>
            <Button className="next_cancel_btn "
                    onClick={handleCancletoggle(anchor, true)}
            >
              {t('pm.project.cancel.btn')}
            </Button>
            <ButtonWithLoader
              className=" next_btn ms-2"
              // disabled={(validateDisable() || isAddBtnLoading === true)}
              loading={isUpdateBtnLoaded}
              onClick={updateClientBillingDetails}
              text={t('pm.drawer.update.btn')}
            >
            </ButtonWithLoader>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="container font-13">
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1">{t('admin.blng.dtls.billing.name')}*</label></Col>
            <Col md={8}>
              <Input type="text" name="billingName"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.billingName}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>


          </Row>

        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.address.line1')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="billingAddress1"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.billingAddress1}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.address.line2')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="billingAddress2"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.billingAddress2}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.city')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="city"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.city}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.country')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="country"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.country}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.state')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="state"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.state}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('integr8.blng.dtls.zipcode')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="zipCode"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.zipCode}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input"/>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1">{t('admin.blng.dtls.gst.no')}*</label></Col>
            <Col md={8}>
              <Input type="text" name="gstNo"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.gstNo}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>

          </Row>

        </div>

        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.pan.no')}*</label></label></Col>
            <Col md={8}>
              <Input type="text" name="panNo"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.panNo}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
                className="mt-1"><label>{t('admin.blng.dtls.tin.no')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="tinNo"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.tinNo}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.name')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocName"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.pocName}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.contact.number')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocPhone"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.pocPhone}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row>
            <Col md={4}><label
              className="mt-1"><label>{t('admin.blng.dtls.key.email.id')}</label></label></Col>
            <Col md={8}>
              <Input type="text" name="pocEmail"
                     onChange={updateBillingdetails}
                     defaultValue={updateClientBilling.pocEmail}
                     placeholder={t('pm.manage.contact.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </div>

      </div>

    </Box>);

  useEffect(() => {
    async function fetchData() {
      await findBillingInstructionByClient(project.clientId);
    }
  }, []);


  return (<div>

    <Row>
      <Col md={8}>
        <h5>{t('program.manager.individual.project.entity.billing')}</h5>
      </Col>
      {userInfo.role.internalName !== 'MT' ? (<Col md={4} className="text-end">
        {project.projectStatus === 'Y' &&
          <div style={{ marginTop: '-2px' }}>
            {['right'].map((anchor) => (
              <React.Fragment key={anchor}>
                <i className="mdi mdi-plus-circle-outline text-info"
                   onClick={toggleDrawerAdd(anchor, true)}
                   style={{ cursor: 'pointer', fontSize: '18px' }}></i>
                <Drawer
                  anchor={anchor}
                  open={state1[anchor]}
                  onClose={toggleDrawerAdd(anchor, false)}
                  BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                  PaperProps={{ style: { boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)' } }}
                >
                  {addList(anchor)}
                </Drawer>
              </React.Fragment>))}
          </div>}
        {project.projectStatus === 'N' && <div></div>}
      </Col>) : <div></div>}
    </Row>
    <div className="" style={{ height: '150px' }}>
      <PerfectScrollbar className="">
        {!_.isEmpty(billingEntityDetails) ? (<div>
          {billingEntityDetails.map((billingEntityDetail, billingIndex) => <div
            key={billingIndex + 1}>
            {userInfo.role.internalName !== 'MT' ? (<Row>
                <Col md={12}>
                  <div className={'d-flex'}>
                    <div className="mt-1"> {billingEntityDetail.billingName}</div>

                    {project.projectStatus === 'N' ? ('') : (
                      <div className="ms-auto me-3 mt-2">
                        {['right'].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <i className="ti-marker-alt text-muted  "
                               onClick={toggleDrawerEdit(billingEntityDetail, anchor, true)}
                               style={{ cursor: 'pointer' }}></i>
                            <Drawer
                              anchor={anchor}
                              open={state2[anchor]}
                              onClose={toggleDrawerEdit(billingEntityDetail, anchor, false)}
                              BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                              PaperProps={{ style: { boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.9)' } }}
                            >
                              {updateList(anchor, billingEntityDetail)}
                            </Drawer>
                          </React.Fragment>))}

                      </div>)}
                  </div>
                </Col>


                {project.projectStatus === 'N' && <Col className="text-right">
                  <div></div>
                </Col>}

              </Row>) :
              (<Row>
                <Col md={12}>
                  <div className="mt-1"> {billingEntityDetail.billingName}</div>
                </Col>
              </Row>)}


            <div className="mt-1"> {billingEntityDetail.billingAddress}</div>
            <div className="mt-1">{t('GST: ')} {billingEntityDetail.gstNo}</div>
            <div className="mt-1">{t('PAN: ')} {billingEntityDetail.panNo}</div>
            <div className="mt-1">{t('TIN: ')} {billingEntityDetail.tinNo}</div>

            {billingEntityDetails.length - 1 !== billingIndex && (<hr />)}


          </div>)}
        </div>) : (
          <span>{t('pm.individual.project.client.billing.details.no.data.text')}</span>)}

      </PerfectScrollbar>

    </div>
  </div>);
};

export default BillingEntityView;
import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var ProjectInternalDocumentController = {


    findAll: function () {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/all`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findById: function (docId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/id/${docId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    findByProject: function (projectId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/project/${projectId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

    insertProjectInternalDocument: function (ProjectDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/file/insert`, {
            method: "post",

            body: ProjectDocument,

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                //  'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    updateProjectDocument: function (ProjectDocument) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/update`, {
            method: "post",

            body: JSON.stringify(ProjectDocument),

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

                'Content-Type': 'application/json'

            })
        }).then(ApiUtils.checkStatus);
    },

    findByDocumentType: function (docTypeId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectinternaldocument/documenttype/${docTypeId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },

};

export default ProjectInternalDocumentController;

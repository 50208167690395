import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import LoginRoutes from "./login-routes";
import conf_prop from "../properties/properties";
import PmRoutes from "./pm-routes";
import NotificationRoutes from "./notification-routes";
import AdminRoutes from "./admin-routes";
import MtMemberRoutes from "./mtmember-routes";
import FinanceRoutes from "./finance-routes";
import PartnerRoutes from "./partner-routes";
import SalesRoutes from "./sales-routes";
import CustomerRoutes from "./customer-routes";


/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/


/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));


/*****Routes******/

const route_path = conf_prop.get("route_path");

const ThemeRoutes = [
    {
        path: '/',
        element: <BlankLayout/>,
        children: [
            {path: '/', name: 'Home', element: <Navigate to={`${route_path}/login/integr8`}/>},
            {path: `${route_path}`, name: 'Home', element: <Navigate to={`${route_path}/login/integr8`}/>},

            {path: '*', element: <Navigate to="/auth/404"/>},
        ],
    },

    {
        path: `${route_path}/program-manager`,
        element: <FullLayout/>,
        children: PmRoutes,
    },

    {
        path: `${route_path}/admin`,
        element: <FullLayout/>,
        children: AdminRoutes,
    },
    {
        path: `${route_path}/management`,
        element: <FullLayout/>,
        children: MtMemberRoutes,
    },
    {
        path: `${route_path}/finance`,
        element: <FullLayout/>,
        children: FinanceRoutes,
    },

    {
        path: `${route_path}/sales`,
        element: <FullLayout/>,
        children: SalesRoutes,
    },

    {
        path: `${route_path}/partner`,
        element: <FullLayout/>,
        children: PartnerRoutes,
    },

    {
        path: `${route_path}/customer`,
        element: <FullLayout/>,
        children: CustomerRoutes,
    },

    {
        path: `${route_path}/login`,
        element: <BlankLayout/>,
        children: LoginRoutes,
    },
    {
        path: `${route_path}/notification`,
        element: <BlankLayout/>,
        children: NotificationRoutes,
    },
    {
        path: '/auth',
        element: <BlankLayout/>,
        children: [
            {path: '404', element: <Error/>},
            {path: '*', element: <Navigate to="/auth/404"/>},

        ],
    },
];


export default ThemeRoutes;

import React, {lazy} from 'react';
import Loadable from '../layouts/loader/Loadable';
import conf_prop from '../properties/properties';
import {Navigate} from 'react-router-dom';
import PmMtPartnerFinanceViewInvoice
    from "../pages/common-components/pm-mt-partner-finance-components/pm-mt-partner-finance-view-invoice";
import GoToOldIntegr8 from "../pages/program-manager/go-to-old-integr8";


const PartnerProjects = Loadable(lazy(() => import('../pages/partner/partner-projects')));
const PartnerIndividualProjectView = Loadable(lazy(() => import('../pages/common-components/partner-finance-components/partner-finance-individual-project')));
const PartnerMyapproval = Loadable(lazy(() => import('../pages/partner/partner-myapproval')));
const PartnerProfileView = Loadable(lazy(() => import('../pages/partner/partner-my-profile')));
const PartnerViewSow = Loadable(lazy(() => import('../pages/partner/partner-view-sow')));
const RaiseInvoiceView = Loadable(lazy(() => import('../pages/partner/partner-raise-invoice')));
const UpdatesView = Loadable(lazy(() => import('../pages/common-components/user-updates')));
const IndividualUpdates = Loadable(lazy(() => import('../pages/common-components/individual-updates')));

const route_path = conf_prop.get('route_path');

const PartnerRoutes = [


    {
        path: `${route_path}/partner/projects`,
        name: 'Projects',
        exact: true,
        element: <PartnerProjects/>,
    },

    {
        path: `${route_path}/partner/partner-individual-projects/:projectId_ENCRYPTED`,
        name: 'individual-projects',
        exact: true,
        element: <PartnerIndividualProjectView/>,
    },

    {
        path: `${route_path}/partner/approval`,
        name: 'My Approvals',
        exact: true,
        element: <PartnerMyapproval/>,
    },

    {
        path: `${route_path}/partner/individual-updates/:mesId_ENCRYPTED`,
        name: 'IndividualUpdates',
        exact: true,
        element: <IndividualUpdates/>,
    },

    {
        path: `${route_path}/partner/my-profile`,
        name: 'My Profile',
        exact: true,
        element: <PartnerProfileView/>,
    },

    {
        path: `${route_path}/partner/view-sow/:psowId_ENCRYPTED`,
        name: 'view issue',
        exact: true,
        element: <PartnerViewSow/>,
    },

    {
        path: `${route_path}/partner/raise-invoice/:sowiId_ENCRYPTED`,
        name: 'raise-invoice',
        exact: true,
        element: <RaiseInvoiceView/>,
    },

    {
        path: `${route_path}/partner/view-invoice/:sowiId_ENCRYPTED`,
        name: 'view-invoice',
        exact: true,
        element: <PmMtPartnerFinanceViewInvoice/>,
        // element: <InvoicesView/>,
    },

    {
        path: `${route_path}/partner/updates`,
        name: 'Updates',
        exact: true,
        element: <UpdatesView/>,
    },
    {
        path: `${route_path}/partner/oldintegr8`,
        name: 'Go to Old Integr8',
        exact: true,
        element: <GoToOldIntegr8/>,
    },
    {path: '*', element: <Navigate to="/auth/404"/>},
];
export default PartnerRoutes;

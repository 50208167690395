import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {NextMaterialTable} from '../../../components/material table';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Box} from "@mui/material";
import {download, generateCsv, mkConfig} from "export-to-csv";
// import {generateCsv, mkConfig} from "export-to-csv";


//convert class to function

function BillingPipelineTab({billingPipeLineData}) {
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [instructionModal, setInstructionModal] = useState(false);

    // console.log("billingPipeLineData", billingPipeLineData)

    function instructionToggle() {
        setInstructionModal((instructionToggle_prev) => {
            return !instructionToggle_prev;
        });
    }

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: 'Billing_Pipeline_Data'
    });

    const handleExportData = () => {
        if (!billingPipeLineData || billingPipeLineData.length === 0) {
            console.warn('No data available to export');
            return;
        }
        const formattedData = billingPipeLineData.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                rowData[col.header] = row[col.accessorKey];
            });
            return rowData;
        });
        const csv = generateCsv(csvConfig)(formattedData);
        download(csvConfig)(csv);
    };

    const columns = useMemo(() => [{
        header: t('program.manager.project.column.text'),
        id: 'projectName',
        accessorKey: 'projectName',
        size: 150,
        enableResizing: false,
        enableSorting: true,
        dataType: 'string',
        accessorFn: (row) => <div className="text_ellipsis" title={row.projectName}>
            {!_.isEmpty(row.projectName) ? row.projectName : '-'}</div>,
        // Cell: ({row}) => {
        //     const rowData = row.original;
        //     return (<div className="text_ellipsis" title={rowData.projectName}>
        //         {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
        //     </div>);
        // },
    },
        {
            header: t('mt.sales.pipeline.tab1.column.client.name.text'),
            id: 'clientName',
            accessorKey: 'clientName',
            size: 150,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            accessorFn: (rowData) => <div className="text_ellipsis" title={rowData.clientName}>
                {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}</div>,
            // Cell: ({row}) => {
            //     const rowData = row.original;
            //     return ((<div className="text_ellipsis" title={rowData.clientName}>
            //         {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
            //     </div>));
            // },
        },
        {
            header: t('mt.sales.pipeline.tab1.column.project.manager.text'),
            id: 'projectManagerName',
            accessorKey: 'projectManagerName',
            size: 150,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            accessorFn: (rowData) => <div className="text_ellipsis" title={rowData.projectManagerName}>
                {!_.isEmpty(rowData.projectManagerName) ? rowData.projectManagerName : '-'}</div>,
            // Cell: ({row}) => {
            //     const rowData = row.original;
            //     return ((<div className="text_ellipsis" title={rowData.projectManagerName}>
            //         {!_.isEmpty(rowData.projectManagerName) ? rowData.projectManagerName : '-'}
            //     </div>));
            // },
        },
        {
            header: t('mt.sales.pipeline.tab1.column.sales.member.text'),
            id: 'salesMemberName',
            accessorKey: 'salesMemberName',
            size: 150,
            enableResizing: false,
            enableSorting: true,
            dataType: 'string',
            accessorFn: (rowData) => <div className="text_ellipsis" title={rowData.salesMemberName}>
                {!_.isEmpty(rowData.salesMemberName) ? rowData.salesMemberName : '-'}</div>,
            // Cell: ({row}) => {
            //     const rowData = row.original;
            //     return ((<div className="text_ellipsis" title={rowData.salesMemberName}>
            //         {!_.isEmpty(rowData.salesMemberName) ? rowData.salesMemberName : '-'}
            //     </div>));
            // },
        },
        {
            header: t('mt.sales.pipeline.tab1.column.april.text'),
            id: 'april',
            accessorKey: 'apr',
            size: 100,
            enableResizing: false,
            dataType: 'number',
            accessorFn: (row) => row.apr,
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },
        {

            header: t('mt.sales.pipeline.tab1.column.may.text'), id: 'may',
            accessorKey: 'may',
            accessorFn: (row) => row.may,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },
        {
            header: t('mt.sales.pipeline.tab1.column.june.text'),
            id: 'june',
            accessorKey: 'jun',
            accessorFn: (row) => row.jun,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },
        {
            header: t('mt.sales.pipeline.tab1.column.july.text'),
            id: 'july',
            accessorKey: 'jul',
            accessorFn: (row) => row.jul,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        },
        {
            header: t('mt.sales.pipeline.tab1.column.august.text'),
            id: 'august',
            accessorKey: 'aug',
            accessorFn: (row) => row.aug,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.september.text'), id: 'september',
            accessorKey: 'sep',
            accessorFn: (row) => row.sep,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.october.text'), id: 'october',
            accessorKey: 'oct',
            accessorFn: (row) => row.oct,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.november.text'), id: 'november',
            accessorKey: 'nov',
            accessorFn: (row) => row.nov,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.december.text'), id: 'december',
            accessorKey: 'december',
            accessorFn: (row) => row.december,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.january.text'), id: 'january',
            accessorKey: 'jan',
            accessorFn: (row) => row.jan,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.february.text'), id: 'february',
            accessorKey: 'feb',
            accessorFn: (row) => row.feb,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,
        }, {
            header: t('mt.sales.pipeline.tab1.column.march.text'), id: 'march',
            accessorKey: 'mar',
            accessorFn: (row) => row.mar,
            size: 100,
            enableResizing: false,
            dataType: 'number',
            Cell: ({cell}) => <div>{cell.getValue()}</div>,

        }]);


    useEffect(() => {
        async function fetchData() {

            set_isLoaded(true);
        }

        fetchData();
    }, []);

    if (!isLoaded) {


        return (<Card className="text-center">
            <CardBody>
                <LoaderSpinner/>
            </CardBody>
        </Card>);
    } else {

        return (<>
            <div className="ms-2" style={{fontSize: '13px'}}>
          <span style={{color: '#333'}}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
              <br/>
              {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
              <a href="#" onClick={instructionToggle} style={{textDecoration: 'underline', color: '#007bff'}}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
            </div>

            <div className="table_hover">
                <NextMaterialTable
                    columns={columns}
                    data={billingPipeLineData}
                    options={{
                        enableColumnPinning: true,
                        enableColumnResizing: true,
                        initialState: {
                            columnOrder: ['projectName', 'clientName', 'projectManagerName', 'salesMemberName', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'january', 'february', 'march']
                        },
                        state: {
                            showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
                        },
                        renderTopToolbarCustomActions: () => (
                            <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap'}}>
                                <Button className="next_btn" onClick={handleExportData}>
                                    Export All Data
                                </Button>
                            </Box>),
                    }}
                />

            </div>


            {/*==========================================Instruction Modal=================================================*/}

            <Modal
                isOpen={instructionModal}
                toggle={instructionToggle}
                className="modal-lg"
                backdrop={'static'}
            >
                <ModalHeader toggle={instructionToggle}>{t(`integr8.billing.pipeline.modal.heading`)}</ModalHeader>
                <ModalBody>
                    <div>
                        <div className={'instruction-body'}>
                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.billing.pipeline.modal.instruction.title1`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content1`)}</span>
                            </section>

                            <section className={'instruction-section'}>
                                <h6 className={'instruction-title'}>{t(`integr8.billing.pipeline.modal.instruction.title2`)}</h6>
                                <span
                                    className={'instruction-content'}>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content2`)}</span>
                            </section>

                            <div style={{color: 'red', textAlign: 'center', lineHeight: '1.5'}}>
                                <span>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content3`)}</span>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button className="integr8_cancel_btn"
                            onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

                </ModalFooter>

            </Modal>


        </>);
    }
    ;
}

export default BillingPipelineTab;

import ApiUtils from '../ApiUtils';
import conf_prop from '../../properties/properties'

var ProjectStatusController = {


        findAll: function () {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/all`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },

        insertProjectStatus: function (ProjectStatus) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/insert`, {
                method: "post",

                body: JSON.stringify(ProjectStatus),

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                    'Content-Type': 'application/json'

                })
            }).then(ApiUtils.checkStatus);
        },

        updateProjectStatus: function (ProjectStatus) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/update`, {
                method: "post",

                body: JSON.stringify(ProjectStatus),

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                    'Content-Type': 'application/json'

                })
            }).then(ApiUtils.checkStatus);
        },

        findByUserInfo: function (userId) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/userinfo/${userId}`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },

        findById: function (pstatId) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/id/${pstatId}`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },

        findByProject: function (projectId) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/project/${projectId}`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },

        findProjectStatusByProject: function (projectId,page = 0, pagesize = 5) {
            const access_token = ApiUtils.getCookie("accessToken");
            const query_params = ApiUtils.queryString({page: page, pagesize: pagesize});

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/project-status/${projectId}?${query_params}`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        },

    findCustomerProjectStatusByProject: function (projectId, page = 0, pagesize = 5) {
        const access_token = ApiUtils.getCookie("accessToken");
        const query_params = ApiUtils.queryString({page: page, pagesize: pagesize});

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/customer-project-status/${projectId}?${query_params}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
        },

    findProjectStatusByContactEmailAndClientId: function (email, clientId) {
        const access_token = ApiUtils.getCookie("accessToken");

        return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/email/client/${email}/${clientId}`, {
            method: "get",

            headers: new Headers({
                Authorization: `Bearer ${access_token}`,

            })
        }).then(ApiUtils.checkStatus);
    },


    findByHealthType: function (healthId) {
            const access_token = ApiUtils.getCookie("accessToken");

            return fetch(`${conf_prop.get("integr8ServiceUrl")}/rest/projectstatus/healthtype/${healthId}`, {
                method: "get",

                headers: new Headers({
                    Authorization: `Bearer ${access_token}`,

                })
            }).then(ApiUtils.checkStatus);
        }
        ,

    }
;

export default ProjectStatusController;

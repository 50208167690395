import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import img1 from '../../../assets/images/users/project-value.png';
import img2 from '../../../assets/images/users/projected-cost.png';
import img3 from '../../../assets/images/users/amount-invoiced.png';
import img4 from '../../../assets/images/users/paidbyclient.png';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import HealthTypeController from '../../../api/controller/healthtype-controller';
import _ from 'lodash';
import ProjectStatusController from '../../../api/controller/projectstatus-controller';
import Moment from 'react-moment';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {sunEditorExtraOptions} from '../../../config/suneditor-options';
import {basicSunEditorListTableOptions} from '../../../config/suneditor-config';
import {UpdatePhoto} from '../user-photo';
import {projectStatusSchema} from '../../../validation/pm-mt-project-overview-project-status-validation';
import classNames from 'classnames';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';

function PmMtProjectOverviewTab({project, findProjectById, userInfo}) {

    const [isLoaded, set_isLoaded] = useState(false);
    const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
    const {t} = useTranslation();
    const [projectStatusList, set_projectStatusList] = useState([]);
    const [healthList, set_healthList] = useState([]);
    const [healthType, set_healthType] = useState(null);
    const [showMe, set_showMe] = useState(false);
    const [statusPage, set_statusPage] = useState({});
    const [page, set_page] = useState(0);
    const [projectStatus, set_projectStatus] = useState({
        statusContent: '', healthType: {healthId: null}
    });
    // console.log('projectStatus', projectStatus);
    const scrollRef = useRef(null);
    const editorRef = useRef();

    const schema = useMemo(() => projectStatusSchema(), []);


    function validateDisable() {

        schema.validate(projectStatus).catch(err => console.warn(err));
        return !schema.isValidSync(projectStatus);
    }

    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };
    const selectRef = useRef();

    function onHealthTypeChange(healthType) {

        set_projectStatus(prevState => {
            return {
                ...prevState, healthType: {healthId: healthType.value}
            }
        })
        set_healthType(healthType);
    }

    function onProjectStatusChange(e) {
        const plainText = e.replace(/<\/?[^>]+(>|$)/g, '');
        set_projectStatus(prevState => {
            return {
                ...prevState, statusContent: plainText,
            }
        })
    }

    async function findProjectStatusByProject(page) {
        const pageSize = 5;
        const tempPage = page;
        await ProjectStatusController.findProjectStatusByProject(project.projectId_ENCRYPTED, tempPage, pageSize).then(results => {
            if (page === 0) {
                set_projectStatusList(results.content);
            } else {
                set_projectStatusList(status_prev => {
                    const projectStatus_input = [...status_prev, ...results.content];
                    return _.uniqBy(projectStatus_input, function (projectStatus) {
                        return projectStatus.pstatId;
                    });
                });
            }
            set_statusPage(results);
            set_page(page_prev => page_prev + 1);
        }).catch(error => {
            console.error(error);
        })
    }

    async function refreshProjectStatus() {
        set_projectStatusList([]);
        const pageInput = 0;
        await findProjectStatusByProject(pageInput);
    }


    async function insertProjectStatusContent() {
        projectStatus.project = {projectId: project.projectId}
        projectStatus.projectAdmin = {paId: project.projectAdmin.paId}
        if (!_.isEmpty(projectStatus.healthType)) {
            set_isLoaded(false);
            set_isUpdateBtnLoading(true)
            try {
                await ProjectStatusController.insertProjectStatus(projectStatus);
                toastify("success", t("common.component.project.status.project.inserted"), t("common.component.project.status.project.inserted"));
                set_projectStatus({statusContent: '', healthType: ''});
                set_healthType(null);
                editorRef.current.setContents('');
                set_page(0);
                //await refreshProjectStatus();
            } catch (error) {
                console.error(error);
            } finally {
                set_isLoaded(true);
                set_isUpdateBtnLoading(false)
                await refreshProjectStatus();
            }
        }
    }

    async function findHealthList() {
        await HealthTypeController.findAll().then(healthList => {
            set_healthList(healthList.map(health => {
                health.label = health.healthStatus;
                health.value = health.healthId;
                return health;
            }));

        }).catch(error => console.error(error));
    }


    useEffect(() => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, [project.projectStatuses]);

    useEffect(() => {
        set_isLoaded(false);

        async function fetchData() {
            await findHealthList();
            await findProjectStatusByProject();

            set_isLoaded(true);

        }

        fetchData();

    }, []);


    return (<div>
        <div className="integr8_next_small_card">
            <Row>
                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#FAC410', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2">
                                    <img style={{height: '30px'}}
                                         src={img1}
                                         alt="total-goal"/>
                                </div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t("integr8.estimated.project.value")}</div>

                                    {!_.isEmpty(project.projections) ? (
                                        <h6 className="goal_count mt-1 ">₹{_.sumBy(project.projections, 'projectionAmount').toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#26C6DA', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img2}
                                                                             alt="ontrack-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t("integr8.projected.cost.text")}</div>
                                    {!_.isEmpty(project.projectServiceCosts) ? (
                                        <h6 className="goal_count mt-1 ">₹{_.sumBy(project.projectServiceCosts, 'totalCost').toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#8961FF', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img3}
                                                                             alt="delayed-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t("integr8.amount.invoiced.text")}</div>
                                    {!_.isEmpty(project.projectInvoices) ? (
                                        <h6 className="goal_count mt-1 ">₹{_.sumBy(project.projectInvoices, 'invoiceAmount').toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3} className="ps-3 pe-3">
                    <div className="next_card" style={{backgroundColor: '#4AC18E', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-2"><img style={{height: '30px'}}
                                                                             src={img4}
                                                                             alt="close-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div
                                        className="mt-2 mb-0 integr8_small_card_text">{t("integr8.paid.by.client.text")}</div>
                                    {!_.isEmpty(project.projectInvoices) ? (
                                        <h6 className="goal_count mt-1">₹{_.sumBy(project.projectInvoices, (invoice) => invoice.paymentStatus === "Y" ? invoice.invoiceAmount : 0).toLocaleString('en-IN')}</h6>) : (
                                        <h6 className="goal_count mt-1">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        </div>

        <div>
            <div className={'tw-font-bold'}>
                {t("program.manager.individual.project.status.project")}
            </div>

            {!_.isEmpty(userInfo) && userInfo.role.internalName === "PM" ? (<div>
                    {project.projectStatus === "Y" && <div>
                        <div className="mt-2">
                            <span style={{
                                color: 'rgba(233, 53, 116, 1)',
                                fontSize: '13px'
                            }}>{t(`integr8.ind.project.project.overview.tab.instruction`)}</span>
                            <SunEditor
                                placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                                name="pageDesc"
                                setOptions={{
                                    ...sunEditorExtraOptions,
                                    mode: 'classic',
                                    minHeight: "100px",
                                    videoWidth: '100%',
                                    videoHeight: '100%',
                                    resizingBar: true,
                                    videoHeightShow: false,
                                    videoRatioShow: false,
                                    videoResizing: false,
                                    showPathLabel: false,
                                    videoSizeOnlyPercentage: false,
                                    imageFileInput: false,
                                    "buttonList": [basicSunEditorListTableOptions],
                                }}
                                defaultValue={projectStatus.statusContent}
                                getSunEditorInstance={getSunEditorInstance}
                                autoFocus={false}
                                // hideToolbar={true}
                                onChange={onProjectStatusChange}
                                setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                            />
                        </div>

                        <Row className="mt-3">
                            <Col md="6">

                                <Select
                                    value={healthType}
                                    classNamePrefix="next_select"
                                    options={healthList}
                                    onChange={onHealthTypeChange}
                                    placeholder={t("integr8.select.project.health")}/>

                            </Col>
                            <Col md="2">
                                <ButtonWithLoader
                                    loading={isUpdateBtnLoading}
                                    className="next_btn next_btn_lg"
                                    disabled={validateDisable()}
                                    onClick={insertProjectStatusContent}
                                    text={t("integr8.post.btn")}
                                >

                                </ButtonWithLoader>
                            </Col>
                        </Row>
                    </div>}
                </div>

            ) : (<div></div>)}
            <br/>

            {!_.isEmpty(projectStatusList) ?
                (<div className="mt-3">
                    {projectStatusList.map(projectStatus => (<div>
                        <Row className="mb-2">
                            {!_.isEmpty(projectStatus.projectAdmin.userInfo.mimeType) ?
                                <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                    <UpdatePhoto
                                      userInfo={projectStatus.projectAdmin.userInfo} />
                                </Col>
                                : <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                    <img className="round" alt={"user-photo"}
                                         src="https://cdn.thinktalentws48.click/image/user_default.png"/>
                                </Col>
                            }
                            <Col lg="10" md="10" sm="11" xs="11" className="ps-0">
                                <div className="pt-1">
                                    <div className="font-14">
                                        {!_.isEmpty(projectStatus) ? projectStatus.projectAdmin.userInfo.name : ""}
                                    </div>

                                    <div style={{fontSize: '12px', display: 'flex'}}>
                                        <span className={'me-1'}>
                                            {!_.isEmpty(projectStatus) ? <Moment
                                                format="DD/MM/YYYY hh:ss a">{projectStatus.genDate}</Moment> : ""}
                                        </span>
                                        <span>
                                            {!_.isEmpty(projectStatus) ?
                                                <i className={projectStatus.healthType.icon}
                                                   style={{color: projectStatus.healthType.color}}/> : ""}
                                        </span>
                                    </div>

                                </div>
                            </Col>
                        </Row>


                        <div className="mb-1 comment-section"
                            //style={{textWrap:"balance"}}
                        >
                            {!_.isEmpty(projectStatus) ? (
                                <div className="comment"
                                     dangerouslySetInnerHTML={{__html: projectStatus.statusContent}}/>) : ''}
                        </div>
                    </div>))}

                    <div className="text-center mt-4 next_wall_btn">
                        <Button
                            disabled={statusPage.last}
                            color={'link'}
                            className={classNames({'disabled-link': statusPage.last})}
                            style={{cursor: 'pointer'}}
                            onClick={() => findProjectStatusByProject(page)}
                        >
                            <div>{t(`integr8.update.button.text.load.more`)}</div>
                            <div><i className="ti-angle-down mt-2"></i></div>
                        </Button>
                    </div>
                </div>)
                : (
                    <div className="mt-3">
                        <div className="text-center">
                            {userInfo.role.internalName === "MT" &&
                                <div className="mt-3"
                                     style={{fontSize: "12px"}}>{t("mt.project.status.no.project.status")}</div>}
                        </div>
                    </div>
                )}
        </div>
    </div>)
}

export default PmMtProjectOverviewTab;
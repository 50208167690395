import * as Yup from 'yup';

export const billingValidationSchema = Yup.object().shape({
    billingName: Yup.string().trim().required('Billing name is required'),
    billingAddress1: Yup.string().trim().required('Billing address is required'),
    city: Yup.string().trim().required('City is required'),
    state: Yup.string().trim().required('State is required'),
    country: Yup.string().trim().required('Country is required'),
    zipCode: Yup.string().trim().required('Zip code is required'),
    gstNo: Yup.string().trim().required('GST number is required'),
    panNo: Yup.string().trim().required('PAN number is required'),
    // tinNo: Yup.string().trim().required('TIN number is required'),
});

import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import {useTranslation} from 'react-i18next';


import img1 from '../../../assets/images/users/project-value.png';
import img2 from '../../../assets/images/users/projected-cost.png';
import img3 from '../../../assets/images/users/amount-invoiced.png';
import 'suneditor/dist/css/suneditor.min.css';
import _ from 'lodash';
import update from 'immutability-helper';
import classNames from 'classnames';
import PmMtProjectRevenueProjection from '../../../views/pm-module/individual-project/pm-mt-project-revenue-projection';
import MonthYearPicker from 'react-month-year-picker';
import ProjectionController from '../../../api/controller/projection-controller';
import PmMtProjectInvoice from '../../../views/pm-module/individual-project/pm-project-invoice';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';


function PmMtCostAnalysisTab({
                                 project,
                                 userInfo,
                                 estimatedTotalProjectValue,
                                 totalProjectCost,
                                 grossMargin,
                                 findProjectById,
                                 salesProjectAdmin,
                             }) {
    const currYear = new Date().getFullYear();
    const {t} = useTranslation();
    const [newServiceModal, set_newServiceModal] = useState(false);
    const [updateServiceModal, set_updateServiceModal] = useState(false);
    const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
    const [activeTab, set_activeTab] = useState(1);
    const [newProjectionModal, set_newProjectionModal] = useState(false);
    const [updateProjectionModal, set_updateProjectionModal] = useState(false);
    const [errorMessage, set_errorMessage] = useState(null);
    const [projectServiceCost, set_projectServiceCost] = useState({
        unitCost: 0,
        unitCount: 0,
        totalCost: 0,
        comments: '',
        project: {projectId: project.projectId},
        serviceType: {stId: ''},
        projectAdmin: project.projectAdmin
    });
    const [projection, set_projection] = useState({
        projectionAmount: null,
        projectionYear: currYear,
        projectionMonth: new Date().getMonth() + 1,
        project: {projectId: null},
        projectAdmin: {paId: null},
    });

    // console.log('projection', projection);

    function handleChangeTab(activeTabInput) {
        set_activeTab(activeTabInput);
    }

    function toggleNewProjectionModal() {
        set_projection({projectionAmount: null, projectionYear: currYear, projectionMonth: new Date().getMonth() + 1});
        set_newProjectionModal((newProjectionModalPrev) => {
            return !newProjectionModalPrev;
        });
        set_errorMessage(null);
    }

    function toggleUpdateProjectionModal() {

        set_updateProjectionModal((updateProjectionModalPrev) => {
            return !updateProjectionModalPrev;
        });
        set_errorMessage(true);
    }

    function onProjectionChange(e) {
        const value = e.target.value;
        if (value === '0') {
            set_errorMessage(true);

        } else if (/^0\d/.test(value)) {
            set_errorMessage(false);
            set_projection(projection_prev => update(projection_prev, { 'projectionAmount': { $set: value.replace(/^0+/, '') } }));
        } else {
            set_errorMessage(false);
            set_projection(projection_prev => update(projection_prev, { 'projectionAmount': { $set: value } }));
        }
    }


    function newServiceModalToggle() {
        set_projectServiceCost({
            unitCost: '',
            unitCount: '',
            totalCost: '',
            comments: '',
            project: {projectId: project.projectId},
            serviceType: {stId: ''},
            projectAdmin: project.projectAdmin
        });

        set_newServiceModal((newServiceModalPrev) => {
            return !newServiceModalPrev;
        });

        set_errorMessage(null);
    }


    function updateServiceModalToggle() {
        set_updateServiceModal((updateServiceModalPrev) => {
            return !updateServiceModalPrev;
        });
    }

    async function insertProjection() {
        set_isAddBtnLoading(true);

        projection.project = {projectId: project.projectId};
        if (userInfo.role.internalName === 'PM') {
            projection.projectAdmin = { paId: project.projectAdmin.paId };
        } else {
            projection.projectAdmin = { paId: salesProjectAdmin.paId };
        }

        await ProjectionController.insertProjection(projection).then(result => {
            toastify('success', t('program.manager.project.actual.rev.inserted.projected'), t('program.manager.project.actual.rev.inserted.projected'));
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            findProjectById();
            toggleNewProjectionModal();
            set_isAddBtnLoading(false);
        });
    }


    useEffect(() => {

        async function fetchData() {
        }

        fetchData();

    }, []);


    return (<div>
        <div className="integr8_next_small_card">
            <Row>
                <Col sm={12} md={6} lg={4} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#FAC410', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-3">
                                    <img style={{height: '30px'}}
                                         src={img1}
                                         alt="total-goal"/>
                                </div>
                                <div className="align-self-center ps-3">
                                    <div className="mt-2 mb-0">{t("integr8.estimated.project.value")}</div>
                                    {!_.isEmpty(project.projections) ? (
                                        <h6 className="goal_count mt-1 ">₹{estimatedTotalProjectValue.toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={6} lg={4} className="ps-3 pe-0">
                    <div className="next_card" style={{backgroundColor: '#26C6DA', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-3"><img style={{height: '30px'}}
                                                                             src={img2}
                                                                             alt="ontrack-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div className="mt-2 mb-0">{t("integr8.projected.cost.text")}</div>
                                    {!_.isEmpty(project.projectServiceCosts) ? (
                                        <h6 className="goal_count mt-1 ">₹{totalProjectCost.toLocaleString('en-IN')}
                                        </h6>) : (<h6 className="goal_count mt-1 ">₹0</h6>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4} className="pe-3 ps-3">
                    <div className="next_card" style={{backgroundColor: '#4AC18E', height: '70%'}}>
                        <div className="next_cardbody">
                            <div className="d-flex">
                                <div className="align-self-center ps-3"><img style={{height: '30px'}}
                                                                             src={img3}
                                                                             alt="delayed-goal"/></div>
                                <div className="align-self-center ps-3">
                                    <div className="mt-2 mb-0">{t("program.manager.cost.analysis.gross.margin")}</div>
                                    {estimatedTotalProjectValue !== 0 ? (<div>
                                        {!_.isEqual(grossMargin, 'NaN') ? (
                                            <h6 className="goal_count mt-1 ">{grossMargin}%
                                            </h6>) : (<h6 className="goal_count mt-1 ">0%
                                        </h6>)}
                                    </div>) : (<div>
                                        -
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

        <div className="d-flex text-end" style={{justifyContent: "right"}}>
            <div className="text-end me-2">
                {userInfo.role.internalName !== 'MT' ? (<span>
                <Button className="next_btn next_btn_lg"
                        onClick={newServiceModalToggle}
                        disabled={project.projectStatus === "N"}
                >
                {t("common.component.project.service.details.modal.new.service")}
            </Button>
            </span>) : (<span></span>)}

            </div>
            <div className="text-end ">
                {userInfo.role.internalName !== 'MT' ? (<Button className="next_btn next_btn_lg text-end"
                                                                onClick={toggleNewProjectionModal}
                                                                disabled={project.projectStatus === 'N'}
                >
                    {t('program.manager.project.actual.rev.new.projection.btn')} </Button>) : (<span></span>)}
            </div>

        </div>


        <div className="next_tabs next_client_tabs modal_containt_e2e">
            <Nav tabs>
                <NavItem>
                    <NavLink

                        className={classNames({active: activeTab === 1})}
                        onClick={() => {
                            handleChangeTab(1);
                        }}
                    >
                        {t('integr8.projected.cost.text')}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink

                        className={classNames({active: activeTab === 2})}
                        onClick={() => {
                            handleChangeTab(2);
                        }}
                    >
                        {t('program.manager.project.actual.rev.nav.projection.invoice')}
                    </NavLink>
                </NavItem>
            </Nav>
        </div>

        <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
                <PmMtProjectInvoice project={project}
                                    userInfo={userInfo}
                                    findProjectById={findProjectById}
                                    newServiceModal={newServiceModal}
                                    newServiceModalToggle={newServiceModalToggle}
                                    updateServiceModal={updateServiceModal}
                                    updateServiceModalToggle={updateServiceModalToggle}
                                    salesProjectAdmin={salesProjectAdmin}
                />
            </TabPane>
            <TabPane tabId={2}>
                <PmMtProjectRevenueProjection project={project}
                                              userInfo={userInfo}
                                              salesProjectAdmin={salesProjectAdmin}
                                              toggleUpdateProjectionModal={toggleUpdateProjectionModal}
                                              updateProjectionModal={updateProjectionModal}
                                              findProjectById={findProjectById}
                />
            </TabPane>
        </TabContent>

        {/* ======================================    Add Projected Revenue  ===================================== */}

        <Modal
            isOpen={newProjectionModal}
            toggle={toggleNewProjectionModal}
            //className={this.props.className}
            backdrop="static"
        >
            <ModalHeader
                toggle={toggleNewProjectionModal}>{t('program.manager.project.actual.rev.modal.header.new.projection')} </ModalHeader>
            <ModalBody>
                {/*<div className="fs-5">Select month and year</div>*/}
                <FormGroup className={'integr8_month-year-picker'}>

                    <MonthYearPicker
                        selectedMonth={projection.projectionMonth}
                        selectedYear={projection.projectionYear}
                        minYear={2020}
                        maxYear={2030}
                        onChangeYear={year => {
                            set_projection(projection_prev => {
                                return {...projection_prev, projectionYear: year};
                            });
                        }}
                        onChangeMonth={month => {
                            set_projection(projection_prev => {
                                return {...projection_prev, projectionMonth: month};
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col md={2} className={'pe-0'}><label
                            className="mt-2">{t('program.manager.project.actual.rev.amount')}</label></Col>
                        <Col md={9} className={'ps-0'}>
                            <Input type="number" name="projectionAmount" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                   min={1}
                                   onChange={onProjectionChange}
                                   placeholder={t('pm.new.projection.modal.placeholder.text')}
                                   className="mb-2 next_input"/>
                            {errorMessage ? (
                              <div
                                className="text-danger font-10">{t('integr8.projection.modal.error.msg')}</div>) : null}
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button className="next_cancel_btn"
                        onClick={toggleNewProjectionModal}
                >
                    {t('integr8.common.cancel.text')}
                </Button>

                <ButtonWithLoader className="next_btn ms-2"
                                  loading={isAddBtnLoading}
                                  disabled={_.isEmpty(projection.projectionAmount) || errorMessage === true}
                                  onClick={insertProjection}
                                  text={t('integr8.common.save.btn')}
                >
                </ButtonWithLoader>

            </ModalFooter>
        </Modal>


    </div>);

}

export default PmMtCostAnalysisTab;
import React, {useEffect, useMemo, useRef, useState} from 'react';
import Moment from 'react-moment';
import Select from 'react-select';
import {Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import ProjectDocumentController from '../../../api/controller/projectdocument-controller';
import UploadFile from '../../../components/upload-file';
import DocumentTypeController from '../../../api/controller/documenttype-controller';
import update from 'immutability-helper';
import {useTranslation} from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import {basicSunEditorListTableOptions} from '../../../config/suneditor-config';
import _ from 'lodash';
import SunEditor from 'suneditor-react';
import ProjectDocumentNoteController from '../../../api/controller/projectdocumentnote-controller';
import {getSoupText} from '../../../util/text-util';
import conf_prop from '../../../properties/properties';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';


function PmMtCustomerDocs({project, userInfo, findProjectById, salesProjectAdmin}) {

    // const {userInfo} = props;
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
    const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
    const [projectDocuments, set_projectDocuments] = useState([]);
    const [uploadDocumentModal, set_uploadDocumentModal] = useState(false);
    const [documentTypeList, set_documentTypeList] = useState([]);
    const [editModal, set_editModal] = useState(false);
    const [documentNoteList, set_documentNoteList] = useState([]);
    const [documentNotePage, set_documentNotePage] = useState({});
    const [page, set_page] = useState(0);
    const [projectDocument, set_projectDocument] = useState({
        documentType: {},
        documentName: "",
        description: "",
        documentDescription: "",
        documentPath: "",
        genDate: new Date(),
        documentStatus: "Y",
        project: project
    });


    const [files, set_files] = useState([]);

    const [projectDocumentNote, set_projectDocumentNote] = useState({
        noteContent: '',
    });

    const [updateDocumentNote, set_updateDocumentNote] = useState({
        noteContent: ''
    });

    const columns = useMemo(() => [{
        name: t('integr8.document.type.text'),
        width: '25%',
        sortable: true,
        selector: rowData => rowData.documentType.docType,
        cell: rowData => (
            <div>
                <div>{rowData.documentType.docType}</div>
                <div className="doc_desc_ellipses font-10" title={rowData.description}
                     style={{color: 'rgba(112, 112, 112, 1)'}}>{rowData.description}</div>
            </div>
        ),
        filter: (rowData, value) => rowData.documentType.docType.toString().toLowerCase().includes(value.toLowerCase()),
    }, {
        name: t('integr8.updated.on.text'),
        width: '15%',
        sortable: true,
        cell: (rowData) => (<div className="next_cell_font_size"><Moment
            format="D MMM YYYY"
            withTitle>
            {rowData.genDate}
        </Moment>
        </div>)

    }, {
        name: t('integr8.document.text'),
        width: '30%',
        sortable: true,
        selector: rowData => rowData.docName,
        filter: (rowData, value) => rowData.docName.toString().toLowerCase().includes(value.toLowerCase()),
        cell: (rowData) => (
            <div className="text_ellipsis" title={rowData.docName}>
                {rowData.docName}
            </div>)
    },
        {
            name: t('integr8.document.added.by'),
            width: '20%',
            sortable: true,
            selector: rowData => _.isEmpty(rowData.projectAdmin.userInfo) ? "-" : rowData.projectAdmin.userInfo.name,
            filter: (rowData, value) => rowData.projectAdmin.userInfo.name.toString().toLowerCase().includes(value.toLowerCase()),
        },
        {
            name: "",
            width: '10%',
            sortable: true, // selector: rowData => rowData.paymentStatus
            cell: row => (<a className=""
                             style={{borderRadius: '4px', fontSize: '12px'}}
                             href={conf_prop.get("integr8ServiceUrl") + "/rest/projectdocument/document-download/" + row.docId_ENCRYPTED}
            ><i className="ti-download" style={{color: "#37A4FE"}}/></a>)

        },]);

    function onUpdateProjectDocumentNoteChange(e) {
        set_updateDocumentNote(updateDocumentNote_prev => update(updateDocumentNote_prev, {"noteContent": {$set: e}}));
    }

    const editorRef = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };


    function updateProjectDocumentNote() {
        set_isUpdateBtnLoading(true);
        const curProject = {projectId: project.projectId};
        ProjectDocumentNoteController.updateProjectDocumentNote(updateDocumentNote).then(result => {
            set_updateDocumentNote(updateProjectDocumentNote_prev => update(updateProjectDocumentNote_prev, {"noteContent": {$set: ""}}));
            toastify("success", t("common.component.project.doc.note.updated"), t("common.component.project.doc.note.updated"));
            set_updateDocumentNote({
                noteContent: ''
            });
            editorRef.current.setContents('');
            set_documentNoteList(documentNoteList_prev => {
                return documentNoteList_prev.map(documentNote => {
                    if (documentNote.pdnId === updateDocumentNote.pdnId) {
                        return {...documentNote, noteContent: updateDocumentNote.noteContent};
                    }
                    return documentNote;
                });
            })

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpdateBtnLoading(false);
            toggleEditdModal();
        });
    }


    function toggleEditdModal(projectDocumentNote) {
        set_editModal((editModalPrev) => {
            return !editModalPrev;
        });
        set_updateDocumentNote({
            ...projectDocumentNote,
            project: {projectId: project.projectId},
            projectAdmin: {paId: project.projectAdmin.paId}
        });
    }

    function toggleUploadDocumentModal() {
        set_files([]);
        set_uploadDocumentModal((uploadDocumentModalPrev) => {
            return !uploadDocumentModalPrev;
        });
    }

    async function findDocumentType() {
        await DocumentTypeController.findAll().then(results => {
            set_documentTypeList(results.map(documentType => {
                documentType.label = documentType.docType;
                documentType.value = documentType.docTypeId;
                return documentType;
            }));
        }).catch((error) => {
            console.error(error);

        }).finally(() => {

        });
    }

    async function findProjectDocumentByProject() {
        await ProjectDocumentController.findByProject(project.projectId_ENCRYPTED).then(results => {
            set_projectDocuments(results);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {

        });
    }

    function onDescriptionChange(e) {
        set_projectDocument(update(projectDocument, {description: {$set: e.target.value}}));
    }

    function onChangeDocumentType(documentType) {
        set_projectDocument(update(projectDocument, {documentType: {$set: documentType}}));
    }

    function handleFileState(files) {
        set_files(files);
    }

    async function insertProjectDocument() {
        set_isAddBtnLoading(true);
        // toastify("success", t("project.document.view.document.uploaded") , t("project.document.view.document.uploaded"));
        const insertProjectDocument = {
            documentType: projectDocument.documentType, description: projectDocument.description, project: project,
        }
        if (userInfo.role.internalName === 'PM') {
            insertProjectDocument.projectAdmin = project.projectAdmin;
        } else {
            insertProjectDocument.projectAdmin = {paId: salesProjectAdmin.paId};
        }
        let formData = new FormData();
        formData.append("ProjectDocumentJson", JSON.stringify(insertProjectDocument));

        files.forEach(file => {
            formData.append("fileUpload", file);
        });

        await ProjectDocumentController.insertProjectDocument(formData).then(result => {

            set_projectDocuments(projectDocuments_prev => projectDocuments_prev.concat(result));
            toastify("success", t("project.document.view.document.uploaded"), t("project.document.view.document.uploaded"));
            set_files([]);

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isAddBtnLoading(false);
            findProjectDocumentByProject();
            toggleUploadDocumentModal();

        });
    }

    const soupText = getSoupText(projectDocumentNote.noteContent);
    const updateSoupText = getSoupText(updateDocumentNote.noteContent);
    useEffect(() => {
        async function fetchData() {
            await findProjectDocumentByProject();
            await findDocumentType();
            set_isLoaded(true);
        }

        fetchData();
    }, []);

    return (

        <div className="mt-2 mb-4">

            {userInfo.role.internalName !== 'MT' && (<div className="row mb-3 ">
                <div className={"col-md-9"} style={{
                    color: 'rgba(233, 53, 116, 1)',
                    fontSize: '13px'
                }}>{t(`integr8.ind.project.project.document.notes.tab.instruction`)}</div>
                <div className={'d-flex col-md-3 justify-content-end'}>
                <Button
                    className="next_btn next_btn_lg text-end"
                    onClick={toggleUploadDocumentModal}
                    disabled={project.projectStatus === "N"}
                >
                    {t("common.components.project.document.view.btn.upload")}
                </Button>
                </div>
            </div>)}

            <div className="feedback_table containt_e2e-datatable mt-3">
                <DataTableView
                    title={t("pm.close.projects.title")}
                    columns={columns}
                    data={projectDocuments}
                    options={{
                        search: true, extendedSearch: false, pagination: true, // title:'Document'
                    }}
                />
            </div>

            { /* ======================================    Upload Document modal ===================================== */}

            <Modal
                isOpen={uploadDocumentModal}
                toggle={toggleUploadDocumentModal}
                // className={this.props.className}
            >
                <ModalHeader
                    toggle={toggleUploadDocumentModal}> {t("common.components.project.document.view.header.upload.document")}</ModalHeader>
                <ModalBody>
                    <Row className={'d-flex justify-content-center align-items-center'}>
                        <Col md={4}>{t("common.components.project.document.view.document.type")}</Col>
                        <Col md={8}>
                            <Select
                                onChange={onChangeDocumentType}
                                options={documentTypeList}
                                classNamePrefix="next_select"
                                placeholder={t("integr8.select.placeholder.text")}/>
                        </Col>
                    </Row>
                    <Row className={'d-flex justify-content-center align-items-center mt-2 mb-4'}>
                        <Col md={4}>{t("common.components.project.int.document.view.doc.desc")}</Col>
                        <Col md={8}>
                            <Input type="text" name="name"
                                   className="next_input"
                                   onChange={(e) => onDescriptionChange(e)}
                                   placeholder={t('common.component.proj.clnt.cont.type.here')}/>
                        </Col>
                    </Row>
                    <FormGroup>
                        {/*<label> {t("common.components.project.document.view.attach.document")}</label>*/}
                        <UploadFile
                            files={files}
                            handleFileState={handleFileState}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button className={'next_cancel_btn me-2'}
                            onClick={toggleUploadDocumentModal}>
                        {t("pm.project.cancel.btn")}
                    </Button>

                    <ButtonWithLoader
                        className="next_btn next_btn_lg"
                        disabled={files.length === 0 || !projectDocument.documentType.docTypeId}
                        onClick={insertProjectDocument}
                        loading={isAddBtnLoading}
                        text={t('integr8.common.save.text')}
                    >
                    </ButtonWithLoader>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={editModal}
                toggle={toggleEditdModal}
                size="lg"
            >
                <ModalHeader
                    toggle={toggleEditdModal}>{t("pm.individual.project.update.note")}</ModalHeader>
                <ModalBody>
                    <div className="mt-2">
                        <SunEditor
                            placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                            name="pageDesc"
                            setOptions={{
                                mode: 'classic',
                                minHeight: '150px',
                                width: 'auto',
                                resizingBar: false,
                                showPathLabel: false,
                                "buttonList": [basicSunEditorListTableOptions],
                            }}
                            getSunEditorInstance={getSunEditorInstance}
                            autoFocus={false}
                            // hideToolbar={true}
                            defaultValue={updateDocumentNote.noteContent}
                            onChange={onUpdateProjectDocumentNoteChange}
                            setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                        />
                    </div>
                    <Row className="mt-3">
                        <Col md="2">
                            <ButtonWithLoader
                                className="next_btn next_btn_lg ms-2"
                                loading={isUpdateBtnLoading}
                                disabled={_.isEmpty(updateSoupText)}
                                onClick={updateProjectDocumentNote}
                                text={t("integr8.post.btn")}
                            >
                            </ButtonWithLoader>

                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>


                </ModalFooter>
            </Modal>
        </div>

    )

}

export default PmMtCustomerDocs;

import React, {useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import conf_prop from '../../../properties/properties';
import BillingPipelineTab from '../../../views/mt-module/dashboard-tabs/billing-pipeline-tab';
import ProjectProfitabilityTab from '../../../views/mt-module/dashboard-tabs/project-profitability-tab';
import PartnerBillingTab from '../../../views/mt-module/dashboard-tabs/partner-billing-tab';
import SalesMtSalesPipelineTab from '../../../views/mt-module/dashboard-tabs/sales-mt-sales-pipeline-tab';
import ApiUtils from '../../../api/ApiUtils';
import ProjectController from '../../../api/controller/project-controller';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import PartnerCalenderTab from '../../../views/mt-module/dashboard-tabs/partner-calender-tab';
import PartnerFeedbackTab from '../../../views/mt-module/dashboard-tabs/partner-Feedback-tab';
import PartnerDecisionTab from '../../../views/mt-module/dashboard-tabs/partner-Decision-tab';
import PartnerUtilizationTab from '../../../views/mt-module/dashboard-tabs/partner-utilization-tab';
import {useDispatch} from 'react-redux';
import {ToggleMiniSidebar} from '../../../store/customizer/CustomizerSlice';


const route_path = conf_prop.get('route_path');

function MtSalesFinanceDashboard() {

    let userInfo = ApiUtils.getLocalStorage('userInfo');
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const {hash} = location;
    const [activeTab, set_activetab] = useState(_.isEmpty(hash) ? 1 : parseInt(hash.replace('#', '')));
    const [billingPipeLineData, set_billingPipeLineData] = useState([]);
    const [salesPipeLineData, set_salesPipeLineData] = useState([]);
    const [projectProfitabilityData, set_projectProfitabilityData] = useState([]);
    const [partnerBillingData, set_partnerBillingData] = useState([]);
    const dispatch = useDispatch();


    function handleChangeTab(activeTabInput) {
        set_activetab(activeTabInput);
    }

    function findBillingPipeLineData() {
        ProjectController.findBillingPipeLineCsv().then((response) => {
            // console.log("response", response);
            set_billingPipeLineData(response)
        }).catch((error) => {
            console.log(error);
        });
    }

    async function findSalesPipeLineData() {
        await ProjectController.findSalesPipeLineData().then((response) => {
            set_salesPipeLineData(response)
        }).catch((error) => {
            console.log(error);
        });
    }

    function findPartnerBillingData() {
        ProjectController.findPartnerBillingData().then((response) => {
            set_partnerBillingData(response)
        }).catch((error) => {
            console.log(error);
        });
    }

    function findProjectProfitabilityData() {
        ProjectController.findProjectProfitabilityData().then((response) => {
            set_projectProfitabilityData(response)
        }).catch((error) => {
            console.log(error);
        });
    }

    function renderTab(activeTab) {

            switch (activeTab) {
                case 1:
                    return (<TabPane tabId={1}>
                        <BillingPipelineTab billingPipeLineData={billingPipeLineData}/>
                    </TabPane>);
                    break;
                case 2:
                    return (<TabPane tabId={2}>
                        <SalesMtSalesPipelineTab salesPipeLineData={salesPipeLineData}/>
                    </TabPane>);
                    break;
                case 3:
                    return (<TabPane tabId={3}>
                        <ProjectProfitabilityTab projectProfitabilityData={projectProfitabilityData}/>
                    </TabPane>);
                    break;
                case 4:
                    return (<TabPane tabId={4}>
                        <PartnerBillingTab partnerBillingData={partnerBillingData}/>
                    </TabPane>);
                    break;
                case 5:
                    return (<TabPane tabId={5}>
                        <PartnerCalenderTab />
                    </TabPane>);
                    break;
                case 6:
                    return (<TabPane tabId={6}>
                        <PartnerFeedbackTab />
                    </TabPane>);
                    break;
                case 7:
                    return (<TabPane tabId={7}>
                        <PartnerDecisionTab />
                    </TabPane>);
                    break;
                case 8:
                    return (<TabPane tabId={8}>
                        <PartnerUtilizationTab />
                    </TabPane>);
                    break;
                default:
                    break;

        }
    }

    useEffect(() => {
        dispatch(ToggleMiniSidebar())
        set_isLoaded(false);

        async function fetchData() {
            if (userInfo.role.internalName === 'MT') {
                await findBillingPipeLineData();
                await findSalesPipeLineData();
                await findProjectProfitabilityData();
                await findPartnerBillingData();
                set_isLoaded(true);
                set_activetab(1);
            } else if (userInfo.role.internalName === 'SALES') {
                await findSalesPipeLineData();
                set_isLoaded(true);
                set_activetab(2);
            } else if (userInfo.role.internalName === 'FINANCE' || userInfo.role.internalName === 'PM') {
                await findPartnerBillingData();
                set_isLoaded(true);
                set_activetab(4);
            }

        }

        fetchData();

    }, []);

    if (!isLoaded) {
        return (<div style={{marginTop: '15%'}}><LoaderSpinner/></div>);
    } else {
        return (
            <div>
                <div className="tw-text-xl fw-bold">
                    Dashboard
                </div>


                <div className="next_tabs next_client_tabs mt-4 containt_e2e">
                    {userInfo.role.internalName === 'SALES' && (
                        <Nav tabs>
                            <NavItem>
                                <NavLink href="#2"
                                         active={activeTab === 2}
                                         onClick={() => {
                                             handleChangeTab(2);
                                         }}>{t('integr8.mt.sales.dshboard.tab2.text')}</NavLink>
                            </NavItem>
                        </Nav>
                    )}
                    {userInfo.role.internalName === 'PM' && (
                      <Nav tabs>
                          <NavItem>
                              <NavLink href="#4"
                                       active={activeTab === 4}
                                       onClick={() => {
                                           handleChangeTab(4);
                                       }}>{t('integr8.mt.sales.dshboard.tab4.text')}</NavLink>
                          </NavItem>
                      </Nav>
                    )}
                    {userInfo.role.internalName === 'MT' && (
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    href="#1"
                                    active={activeTab === 1}
                                    onClick={() => {
                                        handleChangeTab(1);
                                    }}>{t('integr8.mt.sales.dshboard.tab1.text')}

                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#2"
                                         active={activeTab === 2}
                                         onClick={() => {
                                             handleChangeTab(2);
                                         }}>{t('integr8.mt.sales.dshboard.tab2.text')}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#3"
                                         active={activeTab === 3}
                                         onClick={() => {
                                             handleChangeTab(3);
                                         }}>{t('integr8.mt.sales.dshboard.tab3.text')}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#4"
                                         active={activeTab === 4}
                                         onClick={() => {
                                             handleChangeTab(4);
                                         }}>{t('integr8.mt.sales.dshboard.tab4.text')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    )}
                    {userInfo.role.internalName === 'FINANCE' && (
                      <Nav tabs>
                          <NavItem>
                              <NavLink href="#4"
                                       active={activeTab === 4}
                                       onClick={() => {
                                           handleChangeTab(4);
                                       }}>{t('integr8.mt.sales.dshboard.tab4.text')}</NavLink>
                          </NavItem>
                      </Nav>
                    )}


                    <TabContent activeTab={activeTab}>
                        {renderTab(activeTab)}
                    </TabContent>
                </div>

            </div>
        );
    }
}


export default MtSalesFinanceDashboard;
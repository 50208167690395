import React, {useEffect, useMemo, useRef, useState} from 'react';
import Moment from 'react-moment';
import Select from 'react-select';
import {Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import UploadFile from '../../../components/upload-file';
import DocumentTypeController from '../../../api/controller/documenttype-controller';
import update from 'immutability-helper';
import {useTranslation} from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import {basicSunEditorListTableOptions} from '../../../config/suneditor-config';
import _ from 'lodash';
import SunEditor from 'suneditor-react';
import ProjectDocumentNoteController from '../../../api/controller/projectdocumentnote-controller';
import {getSoupText} from '../../../util/text-util';
import conf_prop from '../../../properties/properties';
import {UpdatePhoto} from '../user-photo';
import classNames from 'classnames';
import {ButtonWithLoader, toastify} from '@devopsthink/react-custom-component-util';
import ProjectInternalDocumentController from "../../../api/controller/projectinternaldocument-controller";


function PmInternalDocumentsNotesTab({project, userInfo, findProjectById, salesProjectAdmin}) {

    // const {userInfo} = props;
    const {t} = useTranslation();
    const [isLoaded, set_isLoaded] = useState(false);
    const [isAddBtnLoading, set_isAddBtnLoading] = useState(false);
    const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
    const [projectInternalDocuments, set_projectInternalDocuments] = useState([]);
    const [uploadDocumentModal, set_uploadDocumentModal] = useState(false);
    const [documentTypeList, set_documentTypeList] = useState([]);
    const [editModal, set_editModal] = useState(false);
    const [documentNoteList, set_documentNoteList] = useState([]);
    const [documentNotePage, set_documentNotePage] = useState({});
    const [page, set_page] = useState(0);
    const [projectInternalDocument, set_projectInternalDocument] = useState({
        documentType: {},
        documentName: "",
        description: "",
        documentDescription: "",
        documentPath: "",
        genDate: new Date(),
        documentStatus: "Y",
        project: project
    });

    const [files, set_files] = useState([]);

    const [projectDocumentNote, set_projectDocumentNote] = useState({
        noteContent: '',
    });
    const [updateDocumentNote, set_updateDocumentNote] = useState({
        noteContent: ''
    });


    const columns = useMemo(() => [{
        name: t('integr8.document.type.text'),
        sortable: true,
        width: '25%',
        selector: rowData => rowData.documentType.docType,
        cell: rowData => (
            <div>
                <div>{rowData.documentType.docType}</div>
                <div className="doc_desc_ellipses font-10" title={rowData.description}
                     style={{color: 'rgba(112, 112, 112, 1)'}}>{rowData.description}</div>
            </div>
        ),
        filter: (rowData, value) => rowData.documentType.docType.toString().toLowerCase().includes(value.toLowerCase()),
    }, {
        name: t('integr8.updated.on.text'),
        sortable: true,
        width: '15%',
        cell: (rowData) => (<div className="next_cell_font_size"><Moment
            format="D MMM YYYY"
            withTitle>
            {rowData.genDate}
        </Moment>
        </div>)

    }, {
        name: t('integr8.document.text'),
        sortable: true,
        width: '30%',
        selector: rowData => rowData.internalDocName,
        filter: (rowData, value) => rowData.internalDocName.toString().toLowerCase().includes(value.toLowerCase()),
        cell: (rowData) => (
            <div className="text_ellipsis" title={rowData.internalDocName}>
                {rowData.internalDocName}
            </div>)
    },
        {
            name: t('integr8.document.added.by'),
            width: '20%',
            sortable: true,
            selector: rowData => _.isEmpty(rowData.projectAdmin.userInfo) ? "-" : rowData.projectAdmin.userInfo.name,
            filter: (rowData, value) => rowData.projectAdmin.userInfo.name.toString().toLowerCase().includes(value.toLowerCase()),
        },
        {
            name: "",
            width: '10%',
            sortable: true, // selector: rowData => rowData.paymentStatus
            cell: row => (<a className=""
                             style={{borderRadius: '4px', fontSize: '12px'}}
                             href={conf_prop.get("integr8ServiceUrl") + "/rest/projectinternaldocument/document-download/" + row.pidId_ENCRYPTED}
            ><i className="ti-download" style={{color: "#37A4FE"}}/></a>)

        },]);


    function onProjectDocumentNoteChange(e) {
        set_projectDocumentNote(projectDocumentNote_prev => update(projectDocumentNote_prev, {"noteContent": {$set: e}}));
    }

    function onUpdateProjectDocumentNoteChange(e) {
        set_updateDocumentNote(updateDocumentNote_prev => update(updateDocumentNote_prev, {"noteContent": {$set: e}}));
    }

    const editorRef = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editorRef.current = sunEditor;
    };

    async function findDocumentNoteByProject(page) {
        const pageSize = 5;
        const tempPage = page;
        await ProjectDocumentNoteController.findDocumentNoteByProject(project.projectId_ENCRYPTED, tempPage, pageSize).then(results => {
            if (page === 0) {
                set_documentNoteList(results.content);
            } else {
                set_documentNoteList(documentNote_prev => {
                    const documentNote_input = [...documentNote_prev, ...results.content];
                    return _.uniqBy(documentNote_input, function (documentNote) {
                        return documentNote.pdnId;
                    });
                });
            }
            set_documentNotePage(results);
            set_page(page_prev => page_prev + 1);
        }).catch(error => {
            console.error(error);
        })
    }

    async function refreshDocumentNote() {
        set_documentNoteList([]);
        const pageInput = 0;
        await findDocumentNoteByProject(pageInput);
    }

    async function insertProjectDocumentNote() {
        set_isUpdateBtnLoading(true);
        projectDocumentNote.project = project;
        if (userInfo.role.internalName === 'PM') {
            projectDocumentNote.projectAdmin = project.projectAdmin;
        } else {
            projectDocumentNote.projectAdmin = {paId: salesProjectAdmin.paId};
        }
        await ProjectDocumentNoteController.insertProjectDocumentNote(projectDocumentNote).then(result => {
            set_projectDocumentNote(projectDocumentNote_prev => update(projectDocumentNote_prev, {"noteContent": {$set: ""}}));
            toastify("success", t("common.component.project.doc.note.inserted"), t("common.component.project.doc.note.inserted"));
            set_projectDocumentNote({
                noteContent: '',
                project: {projectId: project.projectId},
                projectAdmin: {paId: project.projectAdmin.paId}
            });
            editorRef.current.setContents('');

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpdateBtnLoading(false);
            refreshDocumentNote();
        });
    }

    function updateProjectDocumentNote() {
        set_isUpdateBtnLoading(true);
        const curProject = {projectId: project.projectId};
        ProjectDocumentNoteController.updateProjectDocumentNote(updateDocumentNote).then(result => {
            set_updateDocumentNote(updateProjectDocumentNote_prev => update(updateProjectDocumentNote_prev, {"noteContent": {$set: ""}}));
            toastify("success", t("common.component.project.doc.note.updated"), t("common.component.project.doc.note.updated"));
            set_updateDocumentNote({
                noteContent: ''
            });
            editorRef.current.setContents('');
            set_documentNoteList(documentNoteList_prev => {
                return documentNoteList_prev.map(documentNote => {
                    if (documentNote.pdnId === updateDocumentNote.pdnId) {
                        return {...documentNote, noteContent: updateDocumentNote.noteContent};
                    }
                    return documentNote;
                });
            })

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isUpdateBtnLoading(false);
            toggleEditdModal();
        });
    }


    function toggleEditdModal(projectDocumentNote) {
        set_editModal((editModalPrev) => {
            return !editModalPrev;
        });
        set_updateDocumentNote({
            ...projectDocumentNote,
            project: {projectId: project.projectId},
            projectAdmin: {paId: project.projectAdmin.paId}
        });
    }

    function toggleUploadDocumentModal() {
        set_files([]);
        set_uploadDocumentModal((uploadDocumentModalPrev) => {
            return !uploadDocumentModalPrev;
        });
    }

    async function findDocumentType() {
        await DocumentTypeController.findAll().then(results => {
            set_documentTypeList(results.map(documentType => {
                documentType.label = documentType.docType;
                documentType.value = documentType.docTypeId;
                return documentType;
            }));
        }).catch((error) => {
            console.error(error);

        }).finally(() => {

        });
    }

    async function findProjectInternalDocumentByProject() {
        await ProjectInternalDocumentController.findByProject(project.projectId_ENCRYPTED).then(results => {
            set_projectInternalDocuments(results);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {

        });
    }

    function onDescriptionChange(e) {
        set_projectInternalDocument(update(projectInternalDocument, {description: {$set: e.target.value}}));
    }

    function onChangeDocumentType(documentType) {
        set_projectInternalDocument(update(projectInternalDocument, {documentType: {$set: documentType}}));
    }

    function handleFileState(files) {
        set_files(files);
    }

    async function insertProjectDocument() {
        set_isAddBtnLoading(true);
        // toastify("success", t("project.document.view.document.uploaded") , t("project.document.view.document.uploaded"));
        const insertProjectDocument = {
            documentType: projectInternalDocument.documentType,
            description: projectInternalDocument.description,
            project: project,
        }

        if (userInfo.role.internalName === 'PM') {
            insertProjectDocument.projectAdmin = project.projectAdmin;
        } else {
            insertProjectDocument.projectAdmin = {paId: salesProjectAdmin.paId};
        }
        let formData = new FormData();
        formData.append("ProjectInternalDocumentJson", JSON.stringify(insertProjectDocument));

        files.forEach(file => {
            formData.append("fileUpload", file);
        });

        await ProjectInternalDocumentController.insertProjectInternalDocument(formData).then(result => {

            set_projectInternalDocuments(projectDocuments_prev => projectDocuments_prev.concat(result));
            toastify("success", t("project.document.view.document.uploaded"), t("project.document.view.document.uploaded"));
            set_files([]);

        }).catch((error) => {
            console.error(error);

        }).finally(() => {
            set_isAddBtnLoading(false);
            findProjectInternalDocumentByProject();
            toggleUploadDocumentModal();

        });
    }

    const soupText = getSoupText(projectDocumentNote.noteContent);
    const updateSoupText = getSoupText(updateDocumentNote.noteContent);
    useEffect(() => {
        async function fetchData() {
            await findProjectInternalDocumentByProject();
            await findDocumentNoteByProject();
            await findDocumentType();
            set_isLoaded(true);
        }

        fetchData();
    }, []);

    return (

        <div className="mt-5 mb-4">
            {userInfo.role.internalName !== 'MT' && (<div className="mb-3 text-end">
                <Button
                    className="next_btn next_btn_lg"
                    onClick={toggleUploadDocumentModal}
                    disabled={project.projectStatus === "N"}
                >
                    {t("common.components.project.document.view.btn.upload")}
                </Button>
            </div>)}

            <div className="feedback_table containt_e2e-datatable mt-3">
                <DataTableView
                    title={t("pm.close.projects.title")}
                    columns={columns}
                    data={projectInternalDocuments}
                    options={{
                        search: true, extendedSearch: false, pagination: true, // title:'Document'
                    }}
                />
            </div>
            {/*    <MaterialTableView title={"Document"} columns={columns}
                                           data={_.orderBy(this.state.projectInternalDocuments, ['genDate'], ['desc'])}/>*/}

            { /* ======================================    Upload Document modal ===================================== */}

            <Modal
                isOpen={uploadDocumentModal}
                toggle={toggleUploadDocumentModal}
                // className={this.props.className}
            >
                <ModalHeader
                    toggle={toggleUploadDocumentModal}> {t("common.components.project.document.view.header.upload.document")}</ModalHeader>
                <ModalBody>
                    <Row className={'d-flex justify-content-center align-items-center'}>
                        <Col md={4}>{t("common.components.project.document.view.document.type")}</Col>
                        <Col md={8}>
                            <Select
                                onChange={onChangeDocumentType}
                                options={documentTypeList}
                                classNamePrefix="next_select"
                                placeholder={t("integr8.select.placeholder.text")}/>
                        </Col>
                    </Row>
                    <Row className={'d-flex justify-content-center align-items-center mt-2 mb-4'}>
                        <Col md={4}>{t("common.components.project.int.document.view.doc.desc")}</Col>
                        <Col md={8}>
                            <Input type="text" name="description"
                                   className="next_input"
                                   onChange={(e) => onDescriptionChange(e)}
                                   placeholder={t('common.component.proj.clnt.cont.type.here')}/>
                        </Col>
                    </Row>
                    <FormGroup>
                        {/*<label> {t("common.components.project.document.view.attach.document")}</label>*/}
                        <UploadFile
                            files={files}
                            handleFileState={handleFileState}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button className={'next_cancel_btn me-2'}
                            onClick={toggleUploadDocumentModal}>
                        {t("pm.project.cancel.btn")}
                    </Button>

                    <ButtonWithLoader
                        className="next_btn next_btn_lg"
                        disabled={files.length === 0 || !projectInternalDocument.documentType.docTypeId}
                        onClick={insertProjectDocument}
                        loading={isAddBtnLoading}
                        text={t('integr8.common.save.text')}
                    >
                    </ButtonWithLoader>
                </ModalFooter>
            </Modal>
            <div>
                <h6 className="mt-3 mb-2">
                    <b>{t("program.manager.document.note.heading")}</b></h6>

                {!_.isEmpty(userInfo) && userInfo.role.internalName !== 'MT' ? (<div>
                        {project.projectStatus === "Y" && <div>
                            <div className="mt-2">
                                <SunEditor
                                    placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                                    name="pageDesc"
                                    setOptions={{
                                        mode: 'classic',
                                        height: '100px',
                                        width: 'auto',
                                        resizingBar: false,
                                        showPathLabel: false,
                                        "buttonList": [basicSunEditorListTableOptions],
                                    }}
                                    getSunEditorInstance={getSunEditorInstance}
                                    autoFocus={false}
                                    // hideToolbar={true}
                                    onChange={onProjectDocumentNoteChange}
                                    setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                                />
                            </div>

                            <div className="text-end mt-2">
                                <ButtonWithLoader
                                    className="next_btn next_btn_lg ms-2"
                                    loading={isUpdateBtnLoading}
                                    disabled={_.isEmpty(soupText)}
                                    onClick={insertProjectDocumentNote}
                                    text={t("integr8.post.btn")}
                                >
                                </ButtonWithLoader>


                            </div>

                        </div>}
                    </div>

                ) : (<div></div>)}

                <br/>

                {!_.isEmpty(documentNoteList) ? (
                    <div className="mt-3">

                        {documentNoteList.map(projectDocumentNote => (
                            <div>
                                <Row className="mb-2">
                                    {!_.isEmpty(projectDocumentNote.projectAdmin.userInfo.mimeType) ?
                                        <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                            <UpdatePhoto
                                                userInfo={projectDocumentNote.projectAdmin.userInfo}/>
                                        </Col>
                                        : <Col lg="1" md="1" sm="12" xs="12" className="pe-0">
                                            <img className="round" alt={"user-photo"}
                                                 src="https://cdn.thinktalentws48.click/image/user_default.png"/>
                                        </Col>
                                    }
                                    <Col lg="10" md="10" sm="11" xs="11" className="ps-0">
                                        <div className="pt-1">
                                            <div className="font-14">
                                                {!_.isEmpty(projectDocumentNote) ? projectDocumentNote.projectAdmin.userInfo.name : ""}
                                            </div>
                                            <div>
                                                <span style={{fontSize: '13px'}}>
                                                    {!_.isEmpty(projectDocumentNote) ? <Moment
                                                        format="DD/MM/YYYY hh:ss a">{projectDocumentNote.genDate}</Moment> : ""}
                                                    </span>
                                                {/*{!_.isEmpty(userInfo) && userInfo.role.internalName === "PM" && project.projectStatus === "Y" ? (*/}

                                                {/*    <span style={{cursor: "pointer"}}*/}
                                                {/*          onClick={() => toggleEditdModal(projectDocumentNote)}>*/}
                                                {/*    <i className="ti-marker-alt ms-2"/>*/}
                                                {/*    </span>*/}

                                                {/*) : (<span></span>)}*/}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>


                                <div className="mb-1 comment-section">
                                    {!_.isEmpty(projectDocumentNote) ? (<div className={'comment'}
                                                                             dangerouslySetInnerHTML={{__html: projectDocumentNote.noteContent}}/>) : ""}
                                </div>

                            </div>))}
                        <div className="text-center mt-4 next_wall_btn">
                            <Button
                                disabled={documentNotePage.last}
                                color={'link'}
                                className={classNames({'disabled-link': documentNotePage.last})}
                                style={{cursor: 'pointer'}}
                                onClick={() => findDocumentNoteByProject(page)}
                            >
                                <div>{t(`integr8.update.button.text.load.more`)}</div>
                                <div><i className="ti-angle-down mt-2"></i></div>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="mt-3">
                        <div className="text-right">
                            {userInfo.role.internalName === "MT" &&
                                <h7 className="fw-bold">{t('mt.document.note.no.document.note')}</h7>}
                        </div>
                    </div>

                )}


            </div>
            <Modal
                isOpen={editModal}
                toggle={toggleEditdModal}
                size="lg"
            >
                <ModalHeader
                    toggle={toggleEditdModal}>{t("pm.individual.project.update.note")}</ModalHeader>
                <ModalBody>
                    <div className="mt-2">
                        <SunEditor
                            placeholder={t(`common.component.proj.clnt.cont.type.here`)}
                            name="pageDesc"
                            setOptions={{
                                mode: 'classic',
                                minHeight: '150px',
                                width: 'auto',
                                resizingBar: false,
                                showPathLabel: false,
                                "buttonList": [basicSunEditorListTableOptions],
                            }}
                            getSunEditorInstance={getSunEditorInstance}
                            autoFocus={false}
                            // hideToolbar={true}
                            defaultValue={updateDocumentNote.noteContent}
                            onChange={onUpdateProjectDocumentNoteChange}
                            setDefaultStyle="font-family: Poppins , sans-serif; font-size: 14px;"
                        />
                    </div>
                    <Row className="mt-3">
                        <Col md="2">
                            <ButtonWithLoader
                                className="next_btn next_btn_lg ms-2"
                                loading={isUpdateBtnLoading}
                                disabled={_.isEmpty(updateSoupText)}
                                onClick={updateProjectDocumentNote}
                                text={t("integr8.post.btn")}
                            >
                            </ButtonWithLoader>

                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>


                </ModalFooter>
            </Modal>
        </div>

    )

}

export default PmInternalDocumentsNotesTab;
